import { useState } from "react";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import Loader from "../../../Fuel/loader/Loader";
import Alert from "@mui/material/Alert";
import axios from "axios";

export default function ITform(props) {
  const [name, setName] = useState("");
  const [issue, setIssue] = useState("");
  const [date, setDate] = useState("");
  const [type, setType] = useState("");
  const [make, setMake] = useState("");
  const [priority, setPriority] = useState("");
  const [showSendButton, setShowSendButton] = useState(true);
  const [message, setMessage] = useState("");
  const [completionAlert, setCompletionAlert] = useState(false);
  const [sentAlert, setSentAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [issueAlert, setIssueAlert] = useState(false);
  const [assetTag, setAssetTag] = useState("");

  const submit = async () => {
    if (issue.length < 25) {
      setMessage("Please expand your description of the issue");
      setIssueAlert(true);
      setTimeout(() => {
        setIssueAlert(false);
      }, 2000);
    } else if (
      name === "" ||
      issue === "" ||
      type === "--Select One--" ||
      make === "" ||
      priority === "--Select One--" ||
      assetTag === ""
    ) {
      setMessage("All fields must be filled, please try again.");
      setCompletionAlert(true);
      setTimeout(() => {
        setCompletionAlert(false);
      }, 2500);
    } else {
      setShowSendButton(false);
      let data = {
        site: props.site,
        name: name,
        issue: issue,
        date: date,
        type: type,
        make: make,
        priority: priority,
        assetTag: assetTag,
      };
      await axios
        .post("https://nitrous.rt-itservices.co.uk/itSupport", data)
        .then((res) => {
          setMessage("Form sent to IT");
          setSentAlert(true);
          setTimeout(() => {
            setSentAlert(false);
          }, 2000);
          setShowSendButton(true);
          setDate("");
          setName("");
          setType("");
          setMake("");
          setAssetTag("");
          setIssue("");
          setPriority("");
          props.rel();
        })
        .catch((err) => {
          console.log(err);
          setMessage("Could not send form, please try again");
          setErrorAlert(true);
          setTimeout(() => {
            setErrorAlert(false);
          }, 2000);
          setShowSendButton(true);
        });
    }
  };

  return (
    <main>
      <iframe
        src="http://localhost:3000/nitropos"
        width="100%"
        height={1000}
        title="NitroFix Iframe"
        style={{ border: 0 }}
      ></iframe>
    </main>
  );
}
