import PrintIndvDay from "./PrintIndvDay";
import Logo from "../Tubbees_Logo.webp";
import RTLogo from "../RT_Logo.png";
import FoodLogo from "../subway.png";

export default function PrintIndvWeek({
    staffMember,
    siteName,
    fullDates,
    currentDate,
    rotaId,
    reloadData,
    confirmedDates,
}) {
    return (
        <tr
            className="employee_row"
            id={`employee-row-${staffMember.employeeDetailsId}`}
        >
            <td
                colSpan={1}
                className="name_cell"
                style={{
                    position: "relative",
                    whiteSpace: "normal",
                    wordBreak: "normal",
                    overflowWrap: "break-word",
                }}
            >
                <div
                     style={{
                        display: "flex",
                        alignItems: "center",
                        maxWidth: "100%",
                      }}
                >
                    {staffMember.icon == "TB" ? (
                        <img
                            src={Logo}
                            style={{
                                width: "30px",
                                height: "30px",
                                marginRight: "10px",
                                flexShrink: 0,
                              }}
                        />
                    ) : staffMember.icon == "FD" ? (
                        <img
                            src={FoodLogo}
                            style={{
                                width: "30px",
                                height: "30px",
                                marginRight: "10px",
                                flexShrink: 0,
                              }}
                        />
                    ) : staffMember.icon == "MG" ? (
                        <img
                            src={RTLogo}
                            style={{
                                width: "30px",
                                height: "30px",
                                marginRight: "10px",
                                flexShrink: 0,
                              }}
                        />
                    ) : (
                        <img
                            src={RTLogo}
                            style={{
                                width: "30px",
                                height: "30px",
                                marginRight: "10px",
                                flexShrink: 0,
                              }}
                        />
                    )}
                    <p
                         style={{
                            margin: 0,
                            whiteSpace: "normal",
                            wordBreak: "normal",
                            overflowWrap: "break-word",
                          }}
                    >
                        {staffMember.firstName.trim()}&nbsp;
                        {staffMember.lastName.trim()}
                    </p>
                </div>
            </td>
            {fullDates.map(function (day) {
                let shifts = staffMember.worked.filter((x) => x.date === day);

                //Sorts shifts in order of start time
                shifts.sort((a, b) =>
                    a.startTime > b.startTime
                        ? 1
                        : b.startTime > a.startTime
                        ? -1
                        : 0
                );

                return (
                    <PrintIndvDay
                        shifts={shifts}
                        currentDate={currentDate}
                        date={day}
                        rotaId={rotaId}
                        siteName={siteName}
                        employee={staffMember}
                        reloadData={reloadData}
                        confirmedDates={confirmedDates}
                    />
                );
            })}
        </tr>
    );
}
