import "./reports.scss";
import { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import UserContext from "../context/userContext";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import NavBar from "../NavBar";
import PayPointSales from "../Paypoint/PayPointSales";
import GlobalReport from "./GlobalReport";
import SubwayReport from "./SubwayReport";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default function ReportsHome() {
    const [value, setValue] = useState(0);
    const { userData, setUserData } = useContext(UserContext);
    const [user, setUser] = useState("");

    useEffect(() => {
        let role = localStorage.getItem("role");
        setUser(role);
    }, []);

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    return (
        <>
            <NavBar />
            <main className="ho__reports main">
                <Box sx={{ width: "100%", marginLeft: "1%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                        >
                            <Tab label="Global Reports" {...a11yProps(0)} />
                            <Tab label="Paypoint Report" {...a11yProps(1)} />
                            <Tab label="Subway EOD" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <GlobalReport />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <PayPointSales />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <SubwayReport />
                    </TabPanel>
                </Box>
            </main>
        </>
    );
}
