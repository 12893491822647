import { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ExploreOffIcon from "@mui/icons-material/ExploreOff";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { Button } from "@mui/material";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

export default function Shifts(props) {
    const [shiftStart, setShiftStart] = useState("");
    const [shiftEnd, setShiftEnd] = useState("");
    const [shifts, setShifts] = useState([]);
    const [expanded, setExpanded] = useState();
    const [currentShift, setCurrentShift] = useState("");

    useEffect(() => {
        setShifts([]);
        setShiftStart("");
        setShiftEnd("");
        setExpanded();
        setCurrentShift("");
        if (props.shiftData.shifts) {
            setShiftStart(
                moment(props.shiftData.startDate, "YYYY-MM-DD").format(
                    "DD/MM/YYYY"
                )
            );
            setShiftEnd(
                moment(props.shiftData.endDate, "YYYY-MM-DD").format(
                    "DD/MM/YYYY"
                )
            );
            setShifts(props.shiftData.shifts);
        }
    }, [props.shiftData]);

    const handleChange = (idx) => {
        if (idx == expanded) {
            setExpanded();
        } else {
            setExpanded(idx);
        }
    };

    const selectShift = (shiftId, type, shiftStart, shiftEnd) => {
        setCurrentShift(shiftId);
        props.selectShift(shiftId, type, shiftStart, shiftEnd);
    };

    return (
        <section>
            {shifts.length > 0 || (shiftStart !== "" && shiftEnd != "") ? (
                <div>
                    <p id="shiftDate">
                        {shiftStart} TO {shiftEnd}
                    </p>
                    {shifts.length > 0 ? (
                        <div id="shiftAccord">
                            {shifts.map(function (shift, idx) {
                                var bgCol;
                                shift.eosId == currentShift
                                    ? (bgCol = "#2c53a0")
                                    : (bgCol = "");
                                var txtCol;
                                shift.eosId == currentShift
                                    ? (txtCol = "white")
                                    : (txtCol = "black");
                                return (
                                    <div className="shift">
                                        <Accordion
                                            sx={{
                                                width: "100%",
                                                margin: "0 auto",
                                            }}
                                            expanded={expanded === idx}
                                            onChange={() => handleChange(idx)}
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                                sx={{
                                                    fontSize: "1rem",
                                                    backgroundColor: bgCol,
                                                    color: txtCol,
                                                }}
                                            >
                                                <div className="shiftData">
                                                    <span>{shift.name}</span>
                                                    <span>
                                                        {shift.deviceName}
                                                    </span>
                                                    <span>
                                                        {moment(
                                                            shift.startTime,
                                                            "YYYY-MM-DD HH:mm:ss"
                                                        ).format(
                                                            "HH:mm DD/MM/YY"
                                                        )}
                                                    </span>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails
                                                sx={{
                                                    borderTop: "1px solid navy",
                                                }}
                                            >
                                                <div className="transactionJournal">
                                                    <p>Transaction Journal</p>
                                                    <Button
                                                        onClick={() =>
                                                            selectShift(
                                                                shift.eosId,
                                                                "TransactionJournal"
                                                            )
                                                        }
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        <MenuBookIcon />
                                                    </Button>
                                                </div>
                                                <hr />
                                                <div className="transactionJournal">
                                                    <p>Safe Drops</p>
                                                    <Button
                                                        onClick={() =>
                                                            selectShift(
                                                                shift.eosId,
                                                                "SafeDrops"
                                                            )
                                                        }
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        <AccountBalanceWalletIcon />
                                                    </Button>
                                                </div>
                                                <hr />
                                                <div className="transactionJournal">
                                                    <p>Summary</p>
                                                    <Button
                                                        onClick={() =>
                                                            selectShift(
                                                                shift.eosId,
                                                                "Summary"
                                                            )
                                                        }
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        <AssessmentIcon />
                                                    </Button>
                                                </div>
                                                <hr />
                                                <div className="transactionJournal">
                                                    <p>Fuel</p>
                                                    <Button
                                                        onClick={() => {
                                                            selectShift(
                                                                shift.eosId,
                                                                "fuel",
                                                                shift.startTime,
                                                                shift.endTime
                                                            );
                                                        }}
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        <LocalGasStationIcon />
                                                    </Button>
                                                </div>
                                                <hr />
                                                <div className="transactionJournal">
                                                    <p>None Scans</p>
                                                    <Button
                                                        onClick={() => {
                                                            selectShift(
                                                                shift.eosId,
                                                                "noneScans"
                                                            )
                                                        }}
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        <PointOfSaleIcon sx={{ fontSize: 20 }} /><PriorityHighIcon sx={{ fontSize: 20 }}/>
                                                    </Button>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <div className="noShifts">
                            <ExploreOffIcon fontSize="large" color="primary" />
                            <p>Explorer Could Not Find Any Shifts</p>
                        </div>
                    )}
                </div>
            ) : (
                <div className="noShifts">
                    <AccessTimeIcon fontSize="large" color="primary" />
                    <h4>Please Select A Date Range To View Shift Data</h4>
                </div>
            )}
        </section>
    );
}
