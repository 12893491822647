import { useEffect, useState } from "react"
import axios from "axios"
import moment from "moment"
import RefundItem from "./RefundItem";
import { Button } from "@mui/material";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import FlagIcon from '@mui/icons-material/Flag';
import { getAllStores } from "../../utils/Api"
import Loader from "../../animations/Loader";

export default function RefundsHome(props) {

    const [refundData, setRefundData] = useState([])
    const [siteList, setSiteList] = useState([])
    const [type, setType] = useState("refund")
    const [refresh, setRefresh] = useState(false)
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [site, setSite] = useState("")
    const [showLoader, setShowLoader] = useState(false)
    const [isPosExplorer, setIsPosExplorer] = useState(false)
    const [flagActive, setFlagActive] = useState(false)

    const url = process.env.REACT_APP_DEPPLOYED

    useEffect(() => {
        setShowLoader(true)

        let start = moment().subtract(1, "day").format("YYYY-MM-DD");
        let end = moment().subtract(1, "day").format("YYYY-MM-DD");

        let data = {
            type: type,
            start: start,
            end: end
        }

        axios.post(`${url}/refund-reports/latest`, data).then((resp) => {
            setRefundData(resp.data)
            setShowLoader(false)
        }).catch((err) => {
            console.log(err)
        })
        getAllStores()
            .then((stores) => {
                setSiteList(stores)
            }).catch((err) => {
                console.log(`couldn't get stores: ${err}`)
            })

        setStartDate(data.start)
        setEndDate(data.end)
    }, [refresh, props])

    const updateFilter = async (key, val) => {
        setShowLoader(true)
        const update = val.toLowerCase();
        console.log(val)
        let data = {
            type: type,
            start: startDate,
            end: endDate,
            siteName: site
        }
        data[key] = update;
        if (key == "type") {
            setType(update)
        } else if (key == "siteName") {
            setSite(update)
        } else if (key == "start") {
            setStartDate(update)
        } else {
            setEndDate(update)
        }

        await axios.post(`${url}/refund-reports/latest`, data).then((resp) => {
            setRefundData(resp.data)
            setShowLoader(false)
        }).catch((err) => {
            console.log(err)
        })
    }

    const flagged = async()=>{
        setFlagActive(!flagActive)
        setShowLoader(true)
        if(flagActive){
            let data = {
                type: type,
                start: startDate,
                end: endDate,
                siteName: site
            }
            await axios.post(`${url}/refund-reports/latest`, data).then((resp) => {
                setRefundData(resp.data)
            }).catch((err) => {
                console.log(err)
            })
        }else{
            await axios.get(`${url}/refund-reports/flagged`).then((resp) => {
                setRefundData(resp.data)
            }).catch((err) => {
                console.log(err)
            })
        }
        setShowLoader(false)
    }

    const reset = () => {
        setSite("")
        setType("refund")
        setFlagActive(false)
        setRefresh(!refresh);
    }

    return (
        <div style={{ marginTop: "1%" }}>

            {props.shiftId ?
                <>
                    <select style={{ marginLeft: "55%" }} onChange={(e) => updateFilter("type", e.target.value)} value={type}>
                        <option>------ Select Type ------</option>
                        <option value="discount">Discounts</option>
                        <option value="refund">Refunds</option>
                    </select>
                </>
                :
                <div style={{ display: "flex", justifyContent: "space-around", width: "65%", margin: "0 auto" }}>
                    <select onChange={(e) => updateFilter("type", e.target.value)} value={type}>
                        <option>------ Select Type ------</option>
                        <option value="discount">Discounts</option>
                        <option value="refund">Refunds</option>
                    </select>
                    <select onChange={(e) => updateFilter("siteName", e.target.value)} value={site}>
                        <option>------ Select Site ------</option>
                        {siteList.map(function (d, i) {
                            return (
                                <option key={i} value={d.siteName.toLowerCase()} >{d.siteName}</option>
                            )
                        })}
                    </select>
                    <input type="date" style={{ width: "10%" }} onChange={(e) => updateFilter("start", e.target.value)} value={startDate} />
                    <input type="date" style={{ width: "10%" }} onChange={(e) => updateFilter("end", e.target.value)} value={endDate} max={startDate} />
                    <Button variant="contained" color={flagActive?"error":"inherit"} onClick={flagged}><FlagIcon /></Button>
                    <Button variant="contained" color="primary" onClick={reset}><RestartAltIcon /></Button>
                </div>
            }

            <div className="cigs_tab">
                {!showLoader ?
                    <table>
                        <thead>
                            <tr>
                                <th>Date/Time</th>
                                <th>Site</th>
                                <th>Item</th>
                                <th>Reason</th>
                                <th>Value (£)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {refundData.length > 0 ?
                                <>
                                    {refundData.map(function (d, i) {
                                        return (
                                            <RefundItem data={d} isPosExplorer={isPosExplorer} />
                                        )
                                    })}
                                </>
                                : <tr><td colSpan={5}>No Results Found</td></tr>}

                        </tbody>
                    </table>
                    : <>
                        <Loader />
                        <p style={{ color: "#000", marginTop: "-1%" }}>Please wait as this may take a couple of minutes</p>
                    </>
                }

            </div>
        </div>
    )
}