import { useState, useEffect } from "react";
import "../styles/fuel.scss";
import NavBar from "../NavBar";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FuelOverview from "./fuel-stats/FuelOverview";
import FuelSales from "./fuel-reports/FuelReporting";
import Keyfuel from "./fuel-card-reports/Keyfuel";
import DriveOffReport from "./drive-off-reports/DriveOffReport";
import Suspended from "./Suspended";
import CCTVAdminHome from "./cctv-admin/CCTVAdminHome";

export default function FuelHome() {
    const [value, setValue] = useState(0);
    const [showMenuIcon, setShowMenuIcon] = useState(false);
    const [showSideMenu, setShowSideMenu] = useState(true);
    const [role, setRole] = useState("");

    useEffect(() => {
        if (window.innerWidth <= 1200) {
            setShowSideMenu(false);
            setShowMenuIcon(true);
        } else {
            setShowSideMenu(true);
        }
        let role = localStorage.getItem("role");
        setRole(role);
    }, [window.innerWidth]);

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    return (
        <>
            <NavBar />
            <div className="main">
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="Fuel Reports" {...a11yProps(0)} />
                    <Tab label="Fuel Stats" {...a11yProps(1)} />
                    <Tab label="Fuel Card Report" {...a11yProps(2)} />
                    <Tab label="Drive Off" {...a11yProps(3)} />
                    <Tab label="Suspended" {...a11yProps(4)} />
                    <Tab label="CCTV" {...a11yProps(5)} />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <FuelSales />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <FuelOverview />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Keyfuel />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <DriveOffReport />
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <Suspended />
                </TabPanel>
                <TabPanel value={value} index={5}>
                    <CCTVAdminHome />
                </TabPanel>
            </div>
        </>
    );
}
