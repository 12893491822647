import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Alert } from "@mui/material";
import moment from "moment";
import axios from "axios";

//Styling
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "450px",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
}; 

export default function ShiftChange({
    shiftId,
    handleClose,
    reload,
    config,
    user,
    start,
    end,
    breakTime,
    timeToDecimal,
    decimalToHoursAndMinutes,
}) {
    const url = process.env.REACT_APP_API_URL;
    const [newStart, setNewStart] = useState(start);
    const [newEnd, setNewEnd] = useState(end);
    const [newHoursWorked, setNewHoursWorked] = useState(0);
    const [changeReasons, setChangeReasons] = useState([]);
    const [selectedReason, setSelectedReason] = useState(0);

    //Alert
    const [alert, setAlert] = useState(false);
    const [severity, setSeverity] = useState("");
    const [message, setMessage] = useState("");
    const [disable, setDisable] = useState(false);

    useEffect(() => {
        axios
            .get(`${url}/rota/reasons/edit`, config)
            .then((res) => {
                setChangeReasons(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const handleAlert = (message, severity) => {
        setDisable(true);
        setMessage(message);
        setSeverity(severity);
        setAlert(true);
        setTimeout(() => {
            setAlert(false);
            setMessage("");
            setSeverity("");
            setDisable(false);
        }, 4000);
    };

    const revertTimes = () => {
        setNewStart(start);
        setNewEnd(end);
    };

    const handleEditShiftValidation = () => {
        if (newStart >= newEnd) {
            handleAlert("Start Time must be before End Time", "warning");
        } else if (selectedReason === 0) {
            handleAlert("You must select a reason", "warning");
        } else {
            handleEditShiftSubmit();
        }
    };

    const handleEditShiftSubmit = () => {
        setDisable(true);
        //Check to see if new hours is Overtime (If so will also open OT modal)
        let isOT = false;
        let _break = parseFloat(timeToDecimal(breakTime));
        let endFormat = moment(end);
        let startFormat = moment(start);
        let newEndFormat = moment(newEnd);
        let newStartFormat = moment(newStart);
        let originalDuration = moment.duration(endFormat.diff(startFormat));
        let durationAsHours = originalDuration.asHours();
        let originalTime = durationAsHours - _break;
        let newDuration = moment.duration(newEndFormat.diff(newStartFormat));
        let newAsHours = newDuration.asHours();
        let newTime = newAsHours - _break;
        let timeInString = decimalToHoursAndMinutes(newTime);
        setNewHoursWorked(timeInString);
        if (newTime > originalTime) {
            isOT = true;
        }

        let data = {
            shiftId: shiftId,
            user: user,
            reasonId: selectedReason,
            updates: {
                startTime: newStart,
                endTime: newEnd,
                hoursWorked: timeInString,
            },
        };

        axios
            .post(`${url}/rota/shift/edit/historic`, data, config)
            .then((res) => {
                handleAlert("Shift changed", "success");
                reload();
            })
            .catch((err) => {
                console.log(err);
                handleAlert("Could not process - Please try again", "error");
            });
    };
    return (
        <>
            <Box sx={style} className="edit_shift_modal">
                <Typography id="modal-modal-title" variant="h4" component="h2">
                    Edit Shift
                </Typography>
                <p>Please enter the new shift details below.</p>
                <section className="input_section">
                    <label>Reason for Change:</label>
                    <select
                        onChange={(e) => {
                            revertTimes();
                            setSelectedReason(parseInt(e.target.value));
                        }}
                    >
                        <option value={0}>Please select a reason</option>
                        {changeReasons.length > 0
                            ? changeReasons.map((reason, i) => {
                                  return (
                                      <option key={i} value={reason.reasonId}>
                                          {reason.name}
                                      </option>
                                  );
                              })
                            : null}
                    </select>
                    {selectedReason != 0 && selectedReason != 4 ? (
                        <>
                            <label>Start Time:</label>
                            <input
                                type={"datetime-local"}
                                value={newStart}
                                onChange={(e) =>
                                    setNewStart(
                                        moment(e.target.value).format(
                                            "YYYY-MM-DD HH:mm"
                                        )
                                    )
                                }
                            />
                            <label>End Time:</label>
                            <input
                                type={"datetime-local"}
                                value={newEnd}
                                onChange={(e) =>
                                    setNewEnd(
                                        moment(e.target.value).format(
                                            "YYYY-MM-DD HH:mm"
                                        )
                                    )
                                }
                            />
                        </>
                    ) : null}
                </section>
                <section className="alert_section">
                    {alert ? (
                        <Alert severity={severity}>{message}</Alert>
                    ) : null}
                </section>
                <section className="btn_section">
                    <Button
                        variant="contained"
                        color="success"
                        onClick={handleEditShiftValidation}
                        disabled={disable}
                    >
                        Submit
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleClose(false)}
                        disabled={disable}
                    >
                        Close
                    </Button>
                </section>
            </Box>
        </>
    );
}
