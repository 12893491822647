import { useState, useEffect } from "react";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import Alert from "@mui/material/Alert";
import Loader from "../loader/Loader";
import AlertTitle from "@mui/material/AlertTitle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export default function Keyfuel() {
    const [site, setSite] = useState("");
    const [date, setDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [paymentType, setPaymentType] = useState("");
    const [siteList, setSiteList] = useState([]);
    const [alert, setAlert] = useState(false);
    const [loader, setLoader] = useState(false);
    const [infoExpand, setInfoExpand] = useState(false);
    const [salesInfo, setSalesInfo] = useState(false);
    const [message, setMessage] = useState("");
    const [payments, setPayments] = useState([]);
    const [total, setTotal] = useState("");

    useEffect(() => {
        axios
            .get("https://nitrous.rt-itservices.co.uk/sites/data")
            .then((res) => {
                setSiteList(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const handleKeyFuel = async () => {
        if (
            site === "--Select Site--" ||
            date === "" ||
            site === "" ||
            endDate === ""
        ) {
            setMessage("Please Select a Site and a Date");
            setAlert(true);
            setTimeout(() => {
                setAlert(false);
            }, 2500);
        } else {
            setLoader(true);
            setInfoExpand(!infoExpand);
            let data = {
                site: site,
                startDate: date,
                endDate: endDate,
                paymentType: paymentType,
            };
            axios
                .post(
                    "https://nitropos-backend.nitrosolutions.co.uk/fuel/fuelReportSQL/cards",
                    data
                )
                .then((res) => {
                    let totalAmount = 0;
                    console.log(res.data);
                    setPayments(res.data);
                    setLoader(false);
                    setSalesInfo(true);
                    for (let i = 0; i < res.data.length; i++) {
                        totalAmount += parseFloat(res.data[i].fuelValue);
                    }
                    setTotal(totalAmount);
                })
                .catch((err) => {
                    console.log(err);
                    setMessage("Something went wrong please try again");
                    setLoader(false);
                    setAlert(true);
                    setTimeout(() => {
                        setAlert(false);
                    }, 3500);
                });
        }
    };

    return (
        <>
            <h1 className="main-heading">Fuel Card Reports</h1>
            <main className="global__report">
                {alert ? (
                    <Alert
                        severity="error"
                        sx={{
                            width: "80vw",
                            left: "10vw",
                            position: "absolute",
                            top: "35vh",
                            zIndex: "10",
                        }}
                    >
                        <AlertTitle>Error</AlertTitle>
                        {message}
                    </Alert>
                ) : (
                    <></>
                )}
                <section className="report__row">
                    <select onChange={(e) => setSite(e.target.value)}>
                        <option>--Select Site--</option>
                        {siteList.map(function (d, i) {
                            return <option>{d.name}</option>;
                        })}
                    </select>
                    <input
                        type="date"
                        onChange={(e) => setDate(e.target.value)}
                    />
                    <input
                        type="date"
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                    <select onChange={(e) => setPaymentType(e.target.value)}>
                        <option>--Select Payment Method--</option>
                        <option>ALLSTAR</option>
                        <option>BP PLUS</option>
                        <option>BP SUPERCHG</option>
                        <option>ESSO CARD</option>
                        <option>KEY FUELS</option>
                        <option value="Local Account">LOCAL ACCOUNT</option>
                    </select>
                    <SearchIcon
                        fontSize="large"
                        color="primary"
                        onClick={() => handleKeyFuel()}
                    />
                </section>
                {loader ? (
                    <div className="loader">
                        <Loader />
                    </div>
                ) : (
                    <></>
                )}
                {salesInfo ? (
                    <div className="info">
                        <div className="info__card">
                            <div className="info__card__column">
                                <h2>Number of Transactions</h2>
                                <h3>{payments.length}</h3>
                            </div>
                            <div className="info__card__column">
                                <h2>Total Amount</h2>
                                <h3>£{parseFloat(total).toFixed(2)}</h3>
                            </div>
                            <div className="info__card__icon">
                                <KeyboardArrowDownIcon
                                    color="primary"
                                    fontSize="large"
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => setInfoExpand(!infoExpand)}
                                />
                            </div>
                        </div>
                        {infoExpand ? (
                            <section className="info__expand">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Date and Time</th>
                                            <th>Value</th>
                                            <th>Fuel Transaction ID</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {payments.map(function (d, i) {
                                            return (
                                                <tr>
                                                    <th>
                                                        {new Date(
                                                            d.paymentTime
                                                        ).toLocaleDateString()}
                                                    </th>
                                                    <th>£{d.fuelValue}</th>
                                                    <th>
                                                        {d.fuelTransactionId}
                                                    </th>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </section>
                        ) : (
                            <></>
                        )}
                    </div>
                ) : (
                    <></>
                )}
            </main>
        </>
    );
}
