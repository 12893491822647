import { useState, Component } from "react";
import ITsystem from "./IT_support/ITsystem";
import { ITsystem as NitroFix } from "./NitroFix/ITsystem";
import RepairSystem from "./repair/RepairSystem";
import CCTVForm from "./cctv/CCTVForm";
import DriveOff from "./driveoff/DriveOff";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import StoreNav from "../StoreNav";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import MouseIcon from "@mui/icons-material/Mouse";
import BuildIcon from "@mui/icons-material/Build";
import VideocamIcon from "@mui/icons-material/Videocam";
import DirectionsCarFilledIcon from "@mui/icons-material/DirectionsCarFilled";
import ErrorBoundary from "../../error/ErrorBoundary";

const StoreDrawer = () => {
  const [itSupport, setITSupport] = useState(true);
  const [repairForm, setRepairForm] = useState(false);
  const [cctvForm, setCCTVForm] = useState(false);
  const [driveoffForm, setDriveoffForm] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [nitrofixForm, setNitrofixForm] = useState(false);

  const drawerMargin = "12vh";
  const mobileDrawerMargin = "6vh";

  const toggleDrawer = () => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenDrawer(!openDrawer);
  };

  const handleChange = (key) => {
    if (key === "IT Support") {
      setITSupport(true);
      setRepairForm(false);
      setCCTVForm(false);
      setDriveoffForm(false);
    } else if (key === "Repair Form") {
      setITSupport(false);
      setRepairForm(true);
      setCCTVForm(false);
      setDriveoffForm(false);
    } else if (key === "CCTV Form") {
      setITSupport(false);
      setRepairForm(false);
      setCCTVForm(true);
      setDriveoffForm(false);
    } else if (key === "Drive Off Form") {
      setITSupport(false);
      setRepairForm(false);
      setCCTVForm(false);
      setDriveoffForm(true);
    } else if (key === "NitroFix") {
      setITSupport(false);
      setRepairForm(false);
      setCCTVForm(false);
      setDriveoffForm(false);
      setNitrofixForm(true);
    }
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            padding: "0",
          }}
        >
          <Toolbar sx={{ width: "100vw", backgroundColor: "#2c53a0" }}>
            <StoreNav />
          </Toolbar>
        </AppBar>

        <Button
          endIcon={<ChevronRightIcon />}
          onClick={toggleDrawer(true)}
          sx={{
            backgroundColor: "#fff",
            height: "5vh",
            marginTop: "22vh",
            position: "absolute",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            ["@media(max-width: 650px)"]: {
              marginTop: "14vh",
            },
            ["@media(min-width: 650px) and (max-width: 700px) "]: {
              marginTop: "11vh",
            },
            ["@media(min-width: 700px) and (max-width: 800px) "]: {
              marginTop: "13vh",
            },
            ["@media(min-width: 800px) and (max-width: 1050px) "]: {
              marginTop: "17vh",
            },
          }}
        >
          Menu
        </Button>

        <Drawer
          open={openDrawer}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          <Toolbar
            sx={{
              marginTop: drawerMargin,
              ["@media(max-width: 600px)"]: {
                marginTop: mobileDrawerMargin,
              },
              ["@media(min-width: 600px) and (max-width: 800px) "]: {
                marginTop: "7vh",
              },
            }}
          />
          <Box onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
            <List>
              <ListItem
                disablePadding
                onClick={() => handleChange("IT Support")}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <MouseIcon />
                  </ListItemIcon>
                  <ListItemText primary={"IT Support"} />
                </ListItemButton>
              </ListItem>
              <ListItem
                disablePadding
                onClick={() => handleChange("Repair Form")}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <BuildIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Repair Form"} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding onClick={() => handleChange("NitroFix")}>
                <ListItemButton>
                  <ListItemIcon>
                    <BuildIcon />
                  </ListItemIcon>
                  <ListItemText primary={"NitroFix"} />
                </ListItemButton>
              </ListItem>
              <ListItem
                disablePadding
                onClick={() => handleChange("CCTV Form")}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <VideocamIcon />
                  </ListItemIcon>
                  <ListItemText primary={"CCTV Form"} />
                </ListItemButton>
              </ListItem>
              <ListItem
                disablePadding
                onClick={() => handleChange("Drive Off Form")}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <DirectionsCarFilledIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Drive Off Form"} />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: "18vh" }}>
          {itSupport ? (
            <ErrorBoundary>
              <ITsystem />
            </ErrorBoundary>
          ) : repairForm ? (
            <ErrorBoundary>
              <RepairSystem />
            </ErrorBoundary>
          ) : cctvForm ? (
            <ErrorBoundary>
              <CCTVForm />
            </ErrorBoundary>
          ) : driveoffForm ? (
            <ErrorBoundary>
              <DriveOff />
            </ErrorBoundary>
          ) : nitrofixForm ? (
            <ErrorBoundary>
              <NitroFix />
            </ErrorBoundary>
          ) : (
            <h1>You don't have permissions for this</h1>
          )}
        </Box>
      </Box>
    </>
  );
};

export default StoreDrawer;
