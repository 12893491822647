//React
import { useState, useEffect } from "react";
//styling
import "./purchase_orders.scss";
//Mui
import { Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
//Utils
import { getSites } from "../utils/Api";

interface Props {
    handleGetOrderLines: (site: number, supplier: number) => void;
    adminSide?: boolean;
    storeSideSite: number;
}

export default function PurchaseOrderSearch({
    handleGetOrderLines,
    adminSide,
    storeSideSite,
}: Props) {
    //URLs
    const url = process.env.REACT_APP_DEPPLOYED;
    //sites
    const [sites, setSites] = useState<any>([]);
    const [adminSite, setAdminSite] = useState<number>(0);
    //Supplier
    const [selectedSupplier, setSelectedSupplier] = useState<number>(0);

    useEffect(() => {
        //Bring in sites
        getSites()
            .then((data) => {
                setSites(data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <section className="purchase_order_search">
            {adminSide ? (
                <>
                    <select
                        onChange={(e) => {
                            setAdminSite(parseInt(e.target.value));
                        }}
                    >
                        <option value={0}>Select site</option>
                        {sites &&
                            sites.map((site: any, i: number) => {
                                return (
                                    <option key={i} value={site.epos}>
                                        {site.name}
                                    </option>
                                );
                            })}
                    </select>
                    <select
                        onChange={(e) =>
                            setSelectedSupplier(parseInt(e.target.value))
                        }
                    >
                        <option value={0}>Please select supplier</option>
                        <option value={199048}>CIGARETTES</option>
                        <option value={197223}>CRAIGY BEARS WAREHOUSE</option>
                        <option value={197553}>KRK BUTCHERS</option>
                        <option value={198671}>TUBBEES</option>
                        <option value={198941}>TUBBEES BAKERY</option>
                        <option value={1}>CHANGE ORDER FORM</option>
                    </select>
                    <Button
                        variant="contained"
                        disabled={adminSite === 0 || selectedSupplier === 0}
                        onClick={() =>
                            handleGetOrderLines(adminSite, selectedSupplier)
                        }
                    >
                        <SearchIcon />
                    </Button>
                </>
            ) : (
                <select
                    onChange={(e) => {
                        handleGetOrderLines(
                            storeSideSite,
                            parseInt(e.target.value)
                        );
                        setSelectedSupplier(parseInt(e.target.value));
                    }}
                    value={selectedSupplier}
                > 
                    <option value={0}>Please select supplier</option>
                    <option value={199048}>CIGARETTES</option>
                    <option value={197223}>CRAIGY BEARS WAREHOUSE</option>
                    <option value={197553}>KRK BUTCHERS</option>
                    {/* <option value={198671}>TUBBEES</option> */}
                    <option value={198941}>TUBBEES BAKERY</option>
                    <option value={1}>CHANGE ORDER FORM</option>
                </select>
            )}
        </section>
    );
}
