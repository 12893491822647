import { useEffect, useState } from "react";
import axios from "axios";
import NavBar from "../ManagerNav";
import Shifts from "./Shifts";
import PosData from "./PosData";
import "./posExplorer.scss";
import SearchIcon from "@mui/icons-material/Search";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { Button } from "@mui/material";
import moment from "moment";
import SafeDrops from "./SafeDrops";
import Summary from "./Summary";
import Fuel from "./Fuel";
import RefundsHome from "../../admin/refunds/RefundsHome";
import MiscProducts from "./MiscProducts";

export default function PosExplorerHome() {
    const [site, setSite] = useState("");
    const [sites, setSites] = useState([]);
    const [siteName, setSiteName] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [message, setMessage] = useState("");
    const [shiftData, setShiftData] = useState({});
    const [shift, setShift] = useState("");
    const [alert, setAlert] = useState(false);
    const [dataType, setDataType] = useState("");
    const [selectedShiftStart, setSelectedShiftStart] = useState("");
    const [selectedShiftEnd, setSelectedShiftEnd] = useState("");
    const [isFuelSite, setIsFuelSite] = useState(false)
    const [searchVal, setSearchVal] = useState("")
    const [showResults, setShowResults] = useState(false)
    const [globalTrans, setGlobalTrans] = useState([])

    const url = process.env.REACT_APP_DEPPLOYED;

    useEffect(async () => {
        let userD = localStorage.getItem("userDetails");
        let user = JSON.parse(userD);
        await axios
            .post(`${url}/eposSQL/getSitesByName`, { sites: user.user.sites })
            .then(async (res0) => {
                if (res0.data.length === 1) {
                    setSite(res0.data[0].eposId);
                    setSiteName(res0.data[0].siteName);
                } else {
                    setSites(res0.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const selectSite = async (idx) => {
        setSiteName(sites[idx].siteName);
        setSite(sites[idx].eposId);
    };

    const reset = async () => {
        setSiteName("");
        setSite("");
        setShift("");
        setShiftData([]);
        setDataType("");
    };

    const adjustDate = async (type, val) => {
        if (type == "start") {
            setStartDate(val);
        } else {
            if (startDate == "") {
                setMessage("Start Date Must Be Selected First");
                setAlert(true);
                setTimeout(() => {
                    setAlert(false);
                }, 3500);
            } else {
                var start = moment(startDate, "YYYY-MM-DD");
                var old = moment(val, "YYYY-MM-DD").isBefore(start);
                if (!old) {
                    var diff = moment(val, "YYYY-MM-DD").diff(start, "days");
                    if (diff > 31) {
                        setMessage("Date Range Can Only Be Up To 31 Days");
                        setAlert(true);
                        setTimeout(() => {
                            setAlert(false);
                        }, 3500);
                    } else {
                        setEndDate(val);
                    }
                } else {
                    setMessage("End Date Cannot Be Before The Start Date");
                    setAlert(true);
                    setTimeout(() => {
                        setAlert(false);
                    }, 3500);
                }
            }
        }
    };

    const fetchShifts = async () => {
        if (siteName == "" || startDate == "" || endDate == "") {
            setMessage("Please Ensure All Fields Have Been Filled");
            setAlert(true);
            setTimeout(() => {
                setAlert(false);
            }, 3500);
        } else {
            var shiftReq = {
                siteId: site,
                startDate: startDate,
                endDate: endDate,
            };
            var data = {
                startDate: startDate,
                endDate: endDate,
                shifts: [],
            };

            await axios
                .post(`${url}/posExplorer/transaction-journal/shifts`, shiftReq)
                .then((resp) => {
                    data.shifts = resp.data;
                })
                .catch((err) => [console.log(err)]);
            setShiftData(data);
            //setStartDate("");
            //setEndDate("");
            setShift("");
            setDataType("");
            await axios.get(`${url}/posExplorer/fuel-site/${site}`).then((resp) => {
                setIsFuelSite(resp.data)
            }).catch((err) => {
                console.log(err)
            })
        }
    };

    const selectShift = async (id, type, shiftStart, shiftEnd) => {
        setShift(id);
        setDataType(type);
        setSelectedShiftStart(moment(shiftStart).format("YYYY-MM-DD HH:mm"));
        setSelectedShiftEnd(moment(shiftEnd).format("YYYY-MM-DD HH:mm"));
    };

    const globalTransactions = async () => {
        let data = {
            siteId: site,
            start: startDate,
            end: endDate
        }

        await axios.post(`${url}/posExplorer/transactions/all`, data).then((resp) => {
            setGlobalTrans(resp.data)
        }).catch((err) => {
            console.log(err);
        })
        setShowResults(!showResults)
    }

    return (
        <>
            <NavBar />
            <main className="main">
                <h1>POS EXPLORER</h1>
                <div id="topDateBar">
                    <div id="siteSelection">
                        <select
                            onChange={(e) => selectSite(e.target.value)}
                        >
                            <option>--- SELECT YOUR SITE ---</option>
                            {sites.map(function (d, i) {
                                return (
                                    <option value={i}>{d.siteName}</option>
                                );
                            })}
                        </select>
                    </div>
                    <div id="repDate">
                        <input
                            type="date"
                            value={startDate}
                            onChange={(e) =>
                                adjustDate("start", e.target.value)
                            }
                        />
                        <span style={{ color: "#000" }}> To </span>
                        <input
                            type="date"
                            value={endDate}
                            onChange={(e) => adjustDate("end", e.target.value)}
                        />
                        <SearchIcon
                            fontSize="medium"
                            color="primary"
                            onClick={() => fetchShifts()}
                        />
                    </div>
                    {alert ? (
                        <Alert
                            severity="warning"
                            sx={{
                                width: "20vw",
                                left: "14vw",
                                position: "absolute",
                                top: "28vh",
                                zIndex: "10",
                                border: "1px solid black",
                                borderRadius: "15px",
                            }}
                        >
                            <AlertTitle>Error</AlertTitle>
                            {message}
                        </Alert>
                    ) : (
                        <></>
                    )}
                </div>

                <div id="mainData">
                    <div id="shifts">
                        <h2>Shifts</h2>
                        <Shifts
                            shiftData={shiftData}
                            selectShift={selectShift}
                            isFuelSite={isFuelSite}
                        />
                    </div>

                    <div id="information">
                        {dataType == "TransactionJournal" ? (
                            <PosData selectedShift={shift} />
                        ) : dataType == "SafeDrops" ? (
                            <SafeDrops selectedShift={shift} />
                        ) : dataType == "Summary" ? (
                            <Summary selectedShift={shift} />
                        ) : dataType == "Refunds" ? (
                            <div style={{ marginLeft: "-26%" }}>
                                <RefundsHome
                                    shiftId={shift}
                                    start={selectedShiftStart}
                                    end={selectedShiftEnd}
                                />
                            </div>
                        ) : dataType == "fuel" ? (
                            <Fuel
                                selectedShift={shift}
                                selectedShiftStart={selectedShiftStart}
                                selectedShiftEnd={selectedShiftEnd}
                                site={site}
                            />
                        ) : dataType == "noneScans" ? (
                            <MiscProducts selectShift={shift} />
                        ) : (
                            <>
                                {/* {shiftData.shifts && shiftData.shifts.length > 0 ?
                                    <div
                                        style={{
                                            padding: "0.9%",
                                            backgroundColor: "#2c53a0",
                                            width: "100%",
                                            display: "flex"
                                        }}
                                    >
                                        <div style={{ width: "50%", margin: "0 auto" }}>
                                            <input style={{ fontSize: "1.2rem", padding: "2px" }} type="text" placeholder="Search Transactions..." onChange={(e) => setSearchVal(e.target.value)} value={searchVal} />
                                            <Button variant="contained" color="success" onClick={globalTransactions}><SearchIcon /></Button>
                                        </div>
                                    </div>
                                    :
                                    <div
                                        style={{
                                            padding: "2.3%",
                                            backgroundColor: "#2c53a0",
                                        }}
                                    ></div>
                                } */}
                                <div
                                    style={{
                                        padding: "2.3%",
                                        backgroundColor: "#2c53a0",
                                    }}
                                ></div>
                                {showResults ? <></> :
                                    <div class="animation-example">
                                        <div class="item">
                                            <div class="line"></div>
                                            <div class="dot"></div>
                                            <div class="circle"></div>
                                        </div>
                                        <div class="item">
                                            <div class="line"></div>
                                            <div class="dot"></div>
                                            <div class="circle"></div>
                                        </div>
                                        <div class="item">
                                            <div class="line"></div>
                                            <div class="dot"></div>
                                            <div class="circle"></div>
                                        </div>
                                        <div class="item">
                                            <div class="line"></div>
                                            <div class="dot"></div>
                                            <div class="circle"></div>
                                        </div>
                                        <div class="item -type2">
                                            <div class="line"></div>
                                            <div class="dot"></div>
                                            <div class="circle"></div>
                                        </div>
                                        <div class="item -type2">
                                            <div class="line"></div>
                                            <div class="dot"></div>
                                            <div class="circle"></div>
                                        </div>
                                        <div class="item -type2">
                                            <div class="line"></div>
                                            <div class="dot"></div>
                                            <div class="circle"></div>
                                        </div>
                                        <div class="item -type2">
                                            <div class="line"></div>
                                            <div class="dot"></div>
                                            <div class="circle"></div>
                                        </div>
                                        <div class="center">
                                            <div class="circle"></div>
                                            <div class="circle"></div>
                                            <div class="circle"></div>
                                        </div>
                                    </div>
                                }
                            </>
                        )}
                    </div>
                </div>
            </main>
        </>
    );
}
