//React
import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
//styling
import "./purchase_order.scss";
//Components
import PurchaseOrderSearch from "../../../purchase_orders/PurchaseOrderSearch";
import PurchaseOrderTable from "../../../purchase_orders/PurchaseOrderTable";
import PurchaseOrderBtns from "../../../purchase_orders/PurchaseOrderBtns";
import OrderHistoryModal from "./OrderHistoryModal";
import Loader from "../../../animations/Loader";
import PurchaseOrderChangeForm from "./PurchaseOrderChangeForm";
//Mui
import Dialog from "@mui/material/Dialog";
import { Alert } from "@mui/material";
import Button from "@mui/material/Button";
import { Modal } from "@mui/material";

//Interface
interface Props {
  approvalPo?: any;
  adminSide?: boolean;
  orderSource: string;
}

export default function StorePurchaseOrder({
  approvalPo,
  adminSide,
  orderSource,
}: Props) {
  //URLs
  const url = process.env.REACT_APP_DEPPLOYED;
  const navigate = useNavigate();
  //Site
  const [isSiteLogin, setIsSiteLogin] = useState<boolean>(false);
  //Purchase Order
  const [orderLines, setorderLines] = useState<any>([]);
  const [lastOrder, setLastOrder] = useState<string>("");
  const [purchaseOrderId, setPurchaseOrderId] = useState<number | null>(null);
  const [selectedSite, setSelectedSite] = useState<number>(0);
  const [selectedSupplier, setSelectedSupplier] = useState<number>(0);
  //Alerts
  const [alert, setAlert] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [severity, setSeverity] = useState<any>("");
  const [disable, setDisable] = useState<boolean>(false);
  //Reload & Loader
  const [loader, setLoader] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  //Dialogs & Modals
  const [cancelDialog, setCancelDialog] = useState<boolean>(false);
  const [userDialog, setUserDialog] = useState<boolean>(false);
  const [ordersModal, setOrdersModal] = useState<boolean>(false);
  //User
  const [user, setUser] = useState<string>("");
  const [submit, setSubmit] = useState<boolean>(false);


  useEffect(() => {
    setReload(false);
    if (approvalPo) {
      setorderLines(approvalPo.products);
      setPurchaseOrderId(approvalPo.poId);
      setSelectedSite(approvalPo.siteId);
      setSelectedSupplier(approvalPo.supplierId);
      // If approval PO is Approved, Denied or Cancelled
      if (
        approvalPo.statusId == 3 ||
        approvalPo.statusId == 4 ||
        approvalPo.statusId == 5
      ) {
        setDisable(true);
      }

      let userD = localStorage.getItem("userDetails");
      if (userD != null) {
        let user = JSON.parse(userD);
        setUser(user.user.user);
      }
    } else {
      //Determine if site logged in
      let localSite = localStorage.getItem("siteID");

      if (localSite != null) {
        setIsSiteLogin(true);
        setSelectedSite(parseInt(localSite));
      }
    }
  }, [reload]);

  //Alerts
  const handleAlert = (
    message: string,
    severity: string,
    duration: number = 4000
  ): void => {
    setDisable(true);
    setMessage(message);
    setSeverity(severity);
    setAlert(true);
    setTimeout(() => {
      setAlert(false);
      setMessage("");
      setSeverity("");
      setDisable(false);
    }, duration);
  };

  const handleGetOrderLines = async (site: number, supplier: number) => {
    setLoader(true);
    setSelectedSite(site);
    setSelectedSupplier(supplier);

    if (supplier === 1) {
      setLoader(false);
    } else {
      let data = {
        siteId: site,
        supplierId: supplier,
        user: adminSide ? user : "Store Zone",
      };

      await axios
        .post(`${url}/purchaseOrder/SupplierProducts`, data)
        .then((res) => {
          if (res.data.poId) {
            setPurchaseOrderId(res.data.poId);
          }
          setorderLines(res.data.products);
          setLoader(false);
        })
        .catch((err) => {
          console.log(err);

          handleAlert("Something went wrong - Please try again", "error");
          setLoader(false);
        });
    }
  };

  //Adds product to purchase order
  const handleAddProduct = (product: any): void => {
    const exists = orderLines.findIndex((line: any, i: number) => {
      return line.eposId === product.eposId;
    });

    if (exists < 0) {
      setorderLines([product, ...orderLines]);
    } else {
      handleAlert("This product has already been added", "warning");
    }
  };

  //Updates the array of orderlines anytime a change is made
  const handleUpdateOrderLines = (
    product: number,
    actual: number,
    units: string
  ): void => {
    let foundProduct = orderLines.findIndex((prod: any, i: number) => {
      return prod.productId === product;
    });
    let newLines = orderLines.map((line: any, i: number) => {
      if (foundProduct === i) {
        return {
          ...line,
          saved: true,
          actualToOrder: actual,
          units: units,
        };
      } else {
        return line;
      }
    });

    setorderLines(newLines);
  };

  //Unsaves a product
  const handleUnsave = (product: number) => {
    let foundProduct = orderLines.findIndex((prod: any, i: number) => {
      return prod.eposId === product;
    });
    let newLines = orderLines.map((line: any, i: number) => {
      if (foundProduct === i) {
        return { ...line, saved: false };
      } else {
        return line;
      }
    });

    setorderLines(newLines);
  };

  //Validates purchase order and once satisfied will submit
  const handleValidation = (): void => {
    let checkAllSaved = orderLines.every((line: any, i: number) => {
      // return line.saved || line.actualToOrder == 0;
      return true;
    });

    if (!checkAllSaved) {
      handleAlert(
        "Please ensure all products have been marked as done",
        "warning"
      );
    } else if (user === "") {
      setSubmit(true);
      setUserDialog(true);
    } else {
      handleSubmit();
    }
  };

  const handleProductsArray = async (products: any) => {
    let orderProds = products;

    if (!approvalPo) {
      orderProds = products.filter(
        (orderLine: any) => orderLine.actualToOrder > 0
      );
    }

    return orderProds;
  };

  //Save for later submission
  const handeleSaveForLater = async () => {
    var buttons = document.getElementById("po_btns")!;
    buttons.style.display = "none";
    if (user == "") {
      setSubmit(false);
      setUserDialog(true);
    } else {
      let nProds = await handleProductsArray(orderLines);

      let data = {
        products: nProds,
        poId: purchaseOrderId,
        staff: user,
        siteId: selectedSite,
        supplierId: selectedSupplier,
        statusId: !approvalPo ? 1 : 2,
        orderSource: orderSource,
        admin: adminSide || approvalPo ? true : false,
      };

      axios
        .post(`${url}/purchaseOrder/Save`, data)
        .then((res) => {
          handleAlert("Saved for later", "success");

          if (approvalPo) {
            console.log("TO ADMIN");
            navigate("/purchase-order-admin");
          } else {
            console.log("TO STOREZONE");
            setReload(true);
          }
        })
        .catch((err) => {
          let errMsg = ``;
          if (err.response.data.includes("PO Cannot")) {
            errMsg = err.response.data;
          } else {
            errMsg = "Something went wrong - Please try again";
          }

          handleAlert(errMsg, "error");
          buttons.style.display = "block";
        });
    }
  };

  //Submits once validation satisfied
  const handleSubmit = async () => {
    var buttons = document.getElementById("po_btns")!;
    buttons.style.display = "none";
    if (user === "") {
      setSubmit(true);
      setUserDialog(true);
    } else {
      setSubmit(false);
      setUserDialog(false);
      setLoader(true);

      let nProds = await handleProductsArray(orderLines);

      let data = {
        products: nProds,
        poId: purchaseOrderId,
        staff: user,
        siteId: selectedSite,
        supplierId: selectedSupplier,
        statusId: orderSource == "Store Zone" ? 2 : 3,
        orderSource: orderSource,
        admin: adminSide || approvalPo ? true : false,
      };

      if (nProds.length > 0) {
        await axios
          .post(`${url}/purchaseOrder/Save`, data)
          .then((res) => {
            handleAlert("Successfully Submitted", "success");
            setLoader(false);

            if (approvalPo) {
              navigate("/purchase-order-admin");
            } else {
              setReload(true);
            }
          })
          .catch((err) => {
            let errMsg = ``;
            if (err.response.data.includes("PO Cannot")) {
              errMsg = err.response.data;
            } else {
              errMsg = "Something went wrong - Please try again";
            }

            handleAlert(errMsg, "error");

            buttons.style.display = "block";
            setLoader(false);
          });
      } else {
        handleAlert("Must be ordering at least 1 product", "error");
      }
    }
  };

  //Cancels the PO
  const handleCancelOrder = (): void => {
    let data = {
      products: orderLines,
      poId: purchaseOrderId,
      staff: user,
      siteId: selectedSite,
      supplierId: selectedSupplier,
      statusId: 5,
      admin: adminSide || approvalPo ? true : false,
    };
    axios
      .post(`${url}/purchaseOrder/Save`, data)
      .then((res) => {
        handleAlert("Successfully Submitted", "success");
      })
      .catch((err) => {
        let errMsg = ``;
        if (err.response.data.includes("PO Cannot")) {
          errMsg = err.response.data;
        } else {
          errMsg = "Something went wrong - Please try again";
        }

        handleAlert(errMsg, "error");
      });

    navigate("/purchase-order-admin");
  };

  return (
    <section className="store_purchase_order">
      <h1 className="po_header">Store PO</h1>
      {!approvalPo && !adminSide ? (
        <div>
          <Button
            variant="contained"
            color="warning"
            onClick={() => setOrdersModal(true)}
          >
            Order History
          </Button>
        </div>
      ) : null}
      {!approvalPo ? (
        <>
          <small className="po_last_order">
            {purchaseOrderId == null && "Creating New Purchase Order"}

            {purchaseOrderId == null && lastOrder != null && " - "}

            {lastOrder != null && purchaseOrderId != null
              ? `Purchase Order Created At: ${moment(lastOrder).format(
                  "DD-MM-YYYY HH:mm"
                )}`
              : `Last Purchase Order: ${moment(lastOrder).format(
                  "DD-MM-YYYY HH:mm"
                )}`}
          </small>

          <PurchaseOrderSearch
            handleGetOrderLines={handleGetOrderLines}
            adminSide={adminSide}
            storeSideSite={selectedSite}
          />
        </>
      ) : null}
      {!loader ? (
        <>
          {orderLines.length > 0 && selectedSupplier !== 1 ? (
            <>
              <PurchaseOrderTable
                orderLines={orderLines}
                handleUpdateOrderLines={handleUpdateOrderLines}
                isSiteLogin={isSiteLogin}
                handleAlert={handleAlert}
                handleUnsave={handleUnsave}
                adminSide={adminSide}
                approvalPo={approvalPo}
                supplier={selectedSupplier}
              />

              <PurchaseOrderBtns
                handleSaveForLater={handeleSaveForLater}
                handleValidation={handleValidation}
                handleCancelOrder={handleCancelOrder}
                setCancelDialog={setCancelDialog}
                handleSubmit={handleSubmit}
                disable={disable}
                isSiteLogin={isSiteLogin}
                approvalPo={approvalPo}
                adminSide={adminSide}
              />
            </>
          ) : selectedSupplier === 1 ? (
            <PurchaseOrderChangeForm
              site={selectedSite}
              setSelectedSupplier={setSelectedSupplier}
              orderSource={orderSource}
            />
          ) : (
            <h2>No Lines</h2>
          )}
        </>
      ) : (
        <Loader />
      )}

      {/* DIALOGS & MODALS */}
      <Dialog open={alert} className="po_alert_dialog">
        <Alert className="alert" severity={severity}>
          {message}
        </Alert>
      </Dialog>
      <Dialog open={cancelDialog} className="cancel_po_dialog">
        <h2>
          You are about to cancel this purchase order. Are you sure you want to
          do this?
        </h2>
        <section className="btn_section">
          <Button variant="contained" onClick={handleCancelOrder}>
            Yes, cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => setCancelDialog(false)}
          >
            No, go back
          </Button>
        </section>
      </Dialog>
      <Dialog open={userDialog} className="user_po_dialog">
        <h2>Please enter your Name</h2>

        <input
          className="user_input"
          type="text"
          onChange={(e) => setUser(e.target.value)}
        />

        <section className="btn_section">
          <Button
            variant="contained"
            onClick={() => {
              if (user === "") {
                handleAlert(
                  "You must enter your name before proceeding",
                  "warning"
                );
              }
              setUserDialog(false);

              if (!submit) {
                handeleSaveForLater();
              } else {
                handleValidation();
              }
            }}
          >
            Confirm
          </Button>
        </section>
      </Dialog>
      <Modal open={ordersModal}>
        <OrderHistoryModal setOrdersModal={setOrdersModal} />
      </Modal>
    </section>
  );
}
