import { useEffect, useState } from "react";
import moment from "moment";
import "moment/min/locales";
import IndvWeek from "./IndvWeek";
import { Button } from "@mui/material";
import ConfirmDay from "./modal/ConfirmDay";
import Modal from "@mui/material/Modal";

export default function Rota({
    site,
    siteName,
    fullDates,
    staffList,
    published,
    currentDate,
    rotaId,
    shortDates,
    user,
    reloadData,
    confirmedDates,
    lastEmployeeId,
}) {
    //Weekly Rota total hours Inc breaks
    const [totalWorkedHours, setTotalWorkedHours] = useState("");
    const [weeklyTotal, setWeeklyTotal] = useState("");

    //Weekly Rota total hours Excl breaks
    const [exclbreaktotal, setExclBreakTotal] = useState("");
    const [exclbreakDaily, setExclBreakDaily] = useState("");

    //Modal
    const [modalCheckDay, setModalCheckDay] = useState("");
    const [confirmationModal, setConfirmationModal] = useState(false);

    useEffect(() => {
        //Calculate the hours for day columns and full rota
        let totalWorked = {};
        let weekHoursTotal = 0;

        let _exclbreakDaily = {};
        let _exclBreakTotal = 0;

        for (let d = 0; d < fullDates.length; d++) {
            totalWorked[fullDates[d]] = 0;
            _exclbreakDaily[fullDates[d]] = 0;
        }

        let employees = staffList.filter(
            (staffMember) => staffMember.worked.length > 0
        );

        let shifts = [];

        for (let e = 0; e < employees.length; e++) {
            for (let s = 0; s < employees[e].worked.length; s++) {
                shifts.push(employees[e].worked[s]);
            }
        }

        //Remove shifts from shift array for time calculation if an the shift share the same day with sickness
        const shiftMap = new Map();

        shifts.forEach((shift) => {
            const shiftKey = `${shift.employeeDetailsId}-${shift.date}`;

            //If the id and date is not in the shiftMap then will be added.
            if (!shiftMap.has(shiftKey)) {
                shiftMap.set(shiftKey, shift);
            } else {
                //If the key and date is already in the shiftMap and one of the items had a leaveType of 3 (sickness) then remove it
                const existingShift = shiftMap.get(shiftKey);
                if (shift.leaveType === 3 || existingShift.leaveType === 3) {
                    shiftMap.delete(shiftKey);
                }
            }
        });

        //Turn the shiftmap back into an array
        const newShiftArray = Array.from(shiftMap.values());

        //Will add up all hours worked and break times for shifts in same date column
        for (let sh = 0; sh < newShiftArray.length; sh++) {
            if (
                !newShiftArray[sh].leave &&
                newShiftArray[sh].hoursWorked &&
                !newShiftArray[sh].noShow
            ) {
                totalWorked[newShiftArray[sh].date] +=
                    moment
                        .duration(newShiftArray[sh].breakTime)
                        .asMilliseconds() +
                    moment
                        .duration(newShiftArray[sh].hoursWorked)
                        .asMilliseconds();

                _exclbreakDaily[newShiftArray[sh].date] += moment
                    .duration(newShiftArray[sh].hoursWorked)
                    .asMilliseconds();

                weekHoursTotal +=
                    moment
                        .duration(newShiftArray[sh].breakTime)
                        .asMilliseconds() +
                    moment
                        .duration(newShiftArray[sh].hoursWorked)
                        .asMilliseconds();

                _exclBreakTotal += moment
                    .duration(newShiftArray[sh].hoursWorked)
                    .asMilliseconds();
            }
        }

        //Loop through weekly date totals and convert to hour and mins string
        for (let h = 0; h < Object.values(totalWorked).length; h++) {
            let numOfMins = moment
                .duration(totalWorked[fullDates[h]])
                .asMinutes();
            let _hours = numOfMins / 60;
            let rhours = Math.floor(_hours);
            let minutes = (_hours - rhours) * 60;
            let rminutes = Math.round(minutes);

            totalWorked[fullDates[h]] = `${rhours}hr(s) ${rminutes}min(s)`;
        }

        for (let h = 0; h < Object.values(_exclbreakDaily).length; h++) {
            let numOfMins = moment
                .duration(_exclbreakDaily[fullDates[h]])
                .asMinutes();
            let _hours = numOfMins / 60;
            let rhours = Math.floor(_hours);
            let minutes = (_hours - rhours) * 60;
            let rminutes = Math.round(minutes);

            _exclbreakDaily[fullDates[h]] = `${rhours}hr(s) ${rminutes}min(s)`;
        }

        let numOfMinsWeekly = moment.duration(weekHoursTotal).asMinutes();
        let _Weeklyhours = numOfMinsWeekly / 60;
        let rWeeklyHours = Math.floor(_Weeklyhours);
        let weeklyMinutes = (_Weeklyhours - rWeeklyHours) * 60;
        let rWeeklyMinutes = Math.round(weeklyMinutes);

        let numOfMinsWeeklyEx = moment.duration(_exclBreakTotal).asMinutes();
        let _WeeklyhoursEx = numOfMinsWeeklyEx / 60;
        let rWeeklyHoursEx = Math.floor(_WeeklyhoursEx);
        let weeklyMinutesEx = (_WeeklyhoursEx - rWeeklyHoursEx) * 60;
        let rWeeklyMinutesEx = Math.round(weeklyMinutesEx);

        setTotalWorkedHours(totalWorked);
        setWeeklyTotal(`${rWeeklyHours}hr(s) ${rWeeklyMinutes}min(s)`);

        setExclBreakDaily(_exclbreakDaily);
        setExclBreakTotal(`${rWeeklyHoursEx}hr(s) ${rWeeklyMinutesEx}min(s)`);
    }, [staffList]);

    useEffect(() => {
        if (lastEmployeeId) {
            const scrollHere = `employee-row-${lastEmployeeId}`;
            const element = document.getElementById(scrollHere);
    
            if (element) {
                element.scrollIntoView({
                    block: "center",
                    behavior: "smooth",
                });
            }
        }
    }, [staffList, lastEmployeeId]);

    return (
        <section className="rota_main">
            <table>
                <thead
                    style={{
                        position: "sticky",
                        top: 0,
                        zIndex: 1000,
                        backgroundColor: "#fff",
                    }}
                >
                    {/* <tr className="confirm_row">
                        <th colSpan={2} className="empty_cell"></th>
                        {fullDates.map(function (day, i) {
                            let dateDiff = moment().diff(
                                moment(day, "YYYY-MM-DD"),
                                "days"
                            );

                            return (
                                <>
                                    {" "}
                                    <th key={i}>
                                        {dateDiff > 0 &&
                                        confirmedDates[day] === false ? (
                                            <>
                                                {" "}
                                                <Button
                                                    color="success"
                                                    variant="outlined"
                                                    className="confirm_day_btn"
                                                    onClick={() => {
                                                        setConfirmationModal(
                                                            true
                                                        );
                                                        setModalCheckDay(day);
                                                    }}
                                                >
                                                    confirm day
                                                </Button>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </th> */}
                    {/* Modal */}
                    {/* <Modal
                                        open={confirmationModal}
                                        aria-labelledby="Confirmation Modal"
                                        aria-describedby="Confirmation Modal"
                                        sx={{
                                            "& .MuiBackdrop-root": {
                                                backgroundColor:
                                                    "rgba(0.4,0,0.2,0.1)",
                                            },
                                        }}
                                    >
                                        <ConfirmDay
                                            rotaId={rotaId}
                                            user={user}
                                            day={modalCheckDay}
                                            reloadData={reloadData}
                                            siteName={siteName}
                                            handleClose={setConfirmationModal}
                                        />
                                    </Modal>
                                </>
                            );
                        })}
                    </tr> */}
                    <tr>
                        <th colSpan={2}>Name</th>
                        {shortDates.map(function (day, i) {
                            return <th key={i}>{day}</th>;
                        })}
                    </tr>
                </thead>
                <tbody> 
                    {staffList.map(function (staffMember, i) {
                        return (
                            <IndvWeek
                                key={i}
                                staffMember={staffMember}
                                fullDates={fullDates}
                                currentDate={currentDate}
                                site={site}
                                siteName={siteName}
                                rotaId={rotaId}
                                user={user}
                                published={published}
                                shortDates={shortDates}
                                reloadData={reloadData}
                                confirmedDates={confirmedDates}
                            />
                        );
                    })} 
                    <tr
                        className="worked_totals"
                        style={{
                            position: "sticky",
                            bottom: 0,
                            zIndex: 1000,
                            backgroundColor: "#2c53a0",
                            color: "#fff",
                            border: "none",
                        }}
                    >
                        <th colSpan={2}>
                            <span>Week Total: </span>
                            {weeklyTotal}
                            <br />
                            <span>Excl. Breaks: </span>
                            {exclbreaktotal}
                        </th>
                        {fullDates.map(function (day, i) {
                            return (
                                <th key={i}>
                                    {totalWorkedHours[day]}
                                    <br />
                                    {exclbreakDaily[day]}
                                </th>
                            );
                        })}
                    </tr>
                </tbody>
            </table>
        </section>
    );
}
