import { useEffect, useState } from "react";
import axios from "axios";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { Tooltip } from "@mui/material";

export default function PriceHistorySite(props) {
    const [siteId, setSiteId] = useState(props.data.siteId);
    const [siteName, setSiteName] = useState(props.data.siteName);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        axios
            .get(
                `https://nitropos-backend.nitrosolutions.co.uk/fuel/fuelHistory/${siteId}`
            )
            .then((res) => {
                if (siteName == "Bearsden") {
                    console.log(res.data);
                }
                setTableData(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <div className="siteHistory">
            <p className="site-name">{siteName}</p>
            <table className="fuelHistoryTable">
                <tr>
                    <th>Grade</th>
                    <th>Previous</th>
                    <th>Current</th>
                    <th>%</th>
                </tr>

                {tableData.map(function (d, i) {
                    return (
                        <tr>
                            <td>
                                <b>{d.type}</b>
                            </td>
                            {d.history.length > 1 ? (
                                <td>
                                    £{d.history[d.history.length - 2].newPrice}
                                </td>
                            ) : (
                                <td>--</td>
                            )}
                            <Tooltip
                                placement="bottom"
                                title={`Last Updated: ${new Date(
                                    d.history[d.history.length - 1].dateUpdated
                                ).toLocaleString()}`}
                            >
                                <td>
                                    £{d.history[d.history.length - 1].newPrice}
                                </td>
                            </Tooltip>

                            {d.history.length > 1 ? (
                                <>
                                    {((d.history[d.history.length - 1]
                                        .newPrice -
                                        d.history[d.history.length - 2]
                                            .newPrice) /
                                        d.history[d.history.length - 2]
                                            .newPrice) *
                                        100 >
                                    0 ? (
                                        <div className="increase">
                                            <span>
                                                {Math.abs(
                                                    (
                                                        ((d.history[
                                                            d.history.length - 1
                                                        ].newPrice -
                                                            d.history[
                                                                d.history
                                                                    .length - 2
                                                            ].newPrice) /
                                                            d.history[
                                                                d.history
                                                                    .length - 2
                                                            ].newPrice) *
                                                        100
                                                    ).toFixed(2)
                                                )}
                                                %
                                            </span>
                                            <br />
                                            <TrendingUpIcon />
                                        </div>
                                    ) : (
                                        <div className="decrease">
                                            <span>
                                                {Math.abs(
                                                    (
                                                        ((d.history[
                                                            d.history.length - 1
                                                        ].newPrice -
                                                            d.history[
                                                                d.history
                                                                    .length - 2
                                                            ].newPrice) /
                                                            d.history[
                                                                d.history
                                                                    .length - 2
                                                            ].newPrice) *
                                                        100
                                                    ).toFixed(2)
                                                )}
                                                %
                                            </span>
                                            <br />
                                            <TrendingDownIcon />
                                        </div>
                                    )}
                                </>
                            ) : (
                                <td>--</td>
                            )}
                        </tr>
                    );
                })}
            </table>
        </div>
    );
}
