import { useEffect, useState } from "react"
import moment from "moment"
import axios from 'axios'
import { Modal } from "@mui/material"
import RefundModal from "./RefundModal"
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

export default function RefundItem(props) {

    const [item, setItem] = useState(props.data)
    const [details, setDetails] = useState({})
    const [openModal, setOpenModal] = useState(false)
    const [showItems, setShowItems] = useState(false)

    const url = process.env.REACT_APP_DEPPLOYED

    useEffect(() => {
        setItem(props.data)
    }, [props])

    const handleClose = () => setOpenModal(false);
    const handleOpen = async (refId) => {
        setOpenModal(true);
        await axios.get(`${url}/refund-reports/getRefundById/${refId}`).then((resp) => {
            setDetails(resp.data)
            console.log(resp.data)
        }).catch((err) => {
            console.log(err)
        })
    }

    return (
        <>
            {item.items.length <= 1 ?
                <tr onClick={() => handleOpen(item.items[0].refId)}>
                    <td>{moment(item.transactionTime).format("DD/MM/YYYY HH:mm")}</td>
                    <td>{item.siteName[0].toUpperCase() + item.siteName.slice(1)}</td>
                    <td>{item.items[0].name}</td>
                    <td>{item.items[0].reason}</td>
                    <td style={{ padding: "1%" }}>{parseFloat(item.value).toFixed(2)}</td>
                </tr>
                :
                <>
                    <tr>
                        <td>{moment(item.transactionTime).format("DD/MM/YYYY HH:mm")}</td>
                        <td>{item.siteName[0].toUpperCase() + item.siteName.slice(1)}</td>
                        <td colSpan={2} onClick={() => setShowItems(!showItems)}>
                        <td style={{ padding: "1%" }}>{item.value}</td>	

                            <KeyboardDoubleArrowDownIcon/> 
                            <span style={{paddingLeft:"10%",paddingRight:"10%"}}>MULTIPLE ITEMS</span> 
                            <KeyboardDoubleArrowDownIcon/>
                        </td>
                        <td style={{ padding: "1%" }}>{parseFloat(item.value).toFixed(2)}</td>
                    </tr>

                    {showItems ?
                        <>
                            {item.items.map(function (d, i) {
                                return (
                                    <tr onClick={() => handleOpen(d.refId)} style={{ backgroundColor: "#64c4e0", color: "#000" }}>
                                        <td></td>
                                        <td></td>
                                        <td>{d.name}</td>
                                        <td>{d.reason}</td>
                                        <td>{parseFloat(d.value).toFixed(2)}</td>
                                    </tr>
                                )
                            })}
                        </>
                        : null
                    }

                </>
            }
            < Modal open={openModal} >
                <RefundModal isPosExplorer={props.isPosExplorer} details={details} open={handleOpen} close={handleClose} />
            </Modal>
        </>
    )
}