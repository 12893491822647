import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import ExploreOffIcon from '@mui/icons-material/ExploreOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Loader from "../../animations/Loader";
import moment from "moment";
import axios from 'axios';

export default function MiscProducts (props) {
    const [products, setProducts] = useState([]);
    const [shift, setShift] = useState("")
    const [loading, setLoading] = useState(false);

    const url = "https://nitropos-backend.nitrosolutions.co.uk";
    //const url = "http://10.0.0.90:4022";

    useEffect(() => {
        setShift("")
        console.log(props.selectShift);
        if (props.selectShift) {
            setLoading(true)
            axios.get(`${url}/posExplorer/none-scans/shift/${props.selectShift}`).then((resp) => {
                setShift(props.selectShift)
                setProducts(resp.data);
                setLoading(false);
            }) .catch ((error) => {
                console.error('Error fetching misc products:', error);
                console.log(error)
            })
        }
    }, [props.selectShift]);

    return (
        <>
        {loading ? 
            <TableRow>
                <TableCell colSpan={7}><div className="loaderIcon"><Loader /></div></TableCell>
            </TableRow>
        : 
            <>
            {products.length > 0 ?
                <>
                <h2>FOUND {products.length} NONE-SCANNABLE PRODUCT(S)</h2>
                <TableContainer component={Paper} sx={{width:"98%", margin:"auto"}}>
                    <Table>
                        <TableHead>
                            <TableRow sx={{backgroundColor:"#2c53a0"}}>
                                <TableCell sx={{fontSize:"17px", color:"#ffffff"}}>Till Name</TableCell>
                                <TableCell sx={{fontSize:"17px", color:"#ffffff"}}>Epos Name</TableCell>
                                <TableCell sx={{fontSize:"17px", color:"#ffffff"}}>Till Qty</TableCell>
                                <TableCell sx={{fontSize:"17px", color:"#ffffff"}}>Actual Qty</TableCell>
                                <TableCell sx={{fontSize:"17px", color:"#ffffff"}}>Till Price</TableCell>
                                <TableCell sx={{fontSize:"17px", color:"#ffffff"}}>Epos Price</TableCell>
                                <TableCell sx={{fontSize:"17px", color:"#ffffff"}}>Time</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            
                            {products.map((product, index) => (
                                <React.Fragment key={index}>
                                    <TableRow>
                                        <TableCell sx={{fontSize:"16px"}}>{product.name}</TableCell>
                                        <TableCell>
                                            {product.productName ?(
                                                <p style={{fontSize:"16px"}}>{product.productName}</p>
                                            ):(
                                                <QuestionMarkIcon sx={{fontSize:"18px"}}/>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                        {product.ingestQty ?(
                                                <p style={{fontSize:"16px"}}>{product.ingestQty}</p>
                                            ):(
                                                <QuestionMarkIcon sx={{fontSize:"18px"}}/>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {product.actualQty ?(
                                                <p style={{fontSize:"16px"}}>{product.actualQty}</p>
                                            ):(
                                                <QuestionMarkIcon sx={{fontSize:"18px"}}/>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {product.price ?(
                                                <p style={{fontSize:"16px"}}>£{product.price}</p>
                                            ):(
                                                <QuestionMarkIcon sx={{fontSize:"18px"}}/>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {product.salePrice ?(
                                                <p style={{fontSize:"16px"}}>£{product.salePrice}</p>
                                            ):(
                                                <QuestionMarkIcon sx={{fontSize:"18px"}}/>
                                            )}
                                        </TableCell>
                                        <TableCell sx={{fontSize:"16px"}}>{moment(product.transactionTime, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss")}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{fontSize:"17px", color:"#2c53a0"}}>Stock Adjusted ?</TableCell>
                                        <TableCell>
                                            {product.stockAdjust ? (
                                                <CheckCircleIcon color="success" />
                                            ) : (
                                                <CancelIcon color="error" />
                                            )}
                                        </TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{fontSize:"17px", color:"#2c53a0"}}>Has an End Of Day ID ?</TableCell>
                                        <TableCell>
                                            {product.eodId ? (
                                                <CheckCircleIcon color="success" />
                                            ) : (
                                                <CancelIcon color="error" />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                                ))}
                            
                        </TableBody>
                    </Table>
                </TableContainer>
                </>
            :
                <div style={{ textAlign: "center", marginTop: "15%", color: "#000" }}>
                    <ExploreOffIcon fontSize="large" color="primary" />
                    <p>No None-Scannable Products Processed On This Shift</p>
                </div>
            }
            </>
        }
        </>
    );
};
