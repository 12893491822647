import { useEffect, useState } from "react";
import axios from "axios";
import PriceHistorySite from "./PriceHistorySite";

export default function PriceHistory() {
    const [allSites, setAllSites] = useState([]);
    useEffect(() => {
        axios
            .get("https://nitropos-backend.nitrosolutions.co.uk/fuel/fuelSites")
            .then((res) => {
                setAllSites(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <div className="fuelHistory">
            {allSites.map(function (d, i) {
                return(
                    <PriceHistorySite data={d}/>
                )
            })}
        </div>
    )



}