import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExploreOffIcon from '@mui/icons-material/ExploreOff';
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import axios from 'axios';
import moment from "moment";
import Loader from "../../animations/Loader";

export default function PosData(props) {
    const [searchVal, setSearchVal] = useState("")
    const [showSearchVal, setShowSearchVal] = useState(false)
    const [shift, setShift] = useState("")
    const [transactions, setTransactions] = useState([])
    const [tempTransactions, setTempTransactions] = useState([])
    const [showLoader, setShowLoader] = useState(false)
    const [alert, setAlert] = useState(false)
    const [message, setMessage] = useState("")

    const url = "https://nitropos-backend.nitrosolutions.co.uk";

    useEffect(() => {
        setShift("")
        setTransactions([])
        setTempTransactions([])
        setSearchVal("")
        if (props.selectedShift) {
            setShowLoader(true)
            axios.get(`${url}/posExplorer/transaction-journal/shift/${props.selectedShift}`).then((resp) => {
                setShowLoader(false)
                setShift(props.selectedShift)
                setTransactions(resp.data)
                setTempTransactions(resp.data)
            }).catch((err) => {
                console.log(err)
            })
        }
    }, [props.selectedShift])

    const searchTransactions = () => {
        if (transactions.length == 0) {
            setMessage("Shift Must Be Selected First");
            setAlert(true);
            setTimeout(() => {
                setAlert(false);
            }, 3500);
        } else if(searchVal==""){
            setMessage("Search Cannot Be Empty");
            setAlert(true);
            setTimeout(() => {
                setAlert(false);
            }, 3500);
        }else{
            setShowSearchVal(true)
            var temp = transactions;
            const data = temp.filter(res => {
                return (JSON.stringify(res).toLocaleLowerCase()).match(searchVal.toLocaleLowerCase());
            });
            // for (let d = 0; d < temp.length; d++) {
            //     var idx = data.findIndex((t) => t.transactionId == temp[d].transactionId)
            //     if (idx >= 0) {
            //         temp[d].bgColour = "#2c53a0"
            //     } else {
            //         temp[d].bgColour = ""
            //     }
            // }
            // temp.sort((a, b) => b.bgColour.localeCompare(a.bgColour))
            setTempTransactions([])
            setShowLoader(true)
            setTimeout(() => {
                setTempTransactions(data)
                setShowLoader(false)
            }, 500)
        }
    }

    const resetTransactions = () => {
        setTempTransactions([])
        setSearchVal("")
        setShowLoader(true)

        var newTrans = transactions;
        newTrans.forEach(function (t) { delete t.bgColour });

        const sortedArray = newTrans.sort((a, b) =>
            moment(a.transactionTime, "YYYY-MM-DD HH:mm:ss").diff(
                moment(b.transactionTime, "YYYY-MM-DD HH:mm:ss")))

        setTimeout(() => {
            setTempTransactions(sortedArray)
            setShowLoader(false)
        }, 500)
    }

    const handleEnter = (e) => {
        if (e.keyCode === 13) {
            searchTransactions();
        }
    };

    return (
        <>
            <div id="searchBar">
                {shift != "" ?
                    <div>
                        <input type="text" placeholder="Enter Search Value Here....." value={searchVal} onChange={(e) => setSearchVal(e.target.value)}
                            onKeyDown={handleEnter} />
                        {/* <SearchIcon
                            fontSize="medium"
                            color="white"
                            onClick={() => searchTransactions()}
                        /> */}
                        {searchVal != "" ?
                            <HighlightOffIcon
                                fontSize="medium"
                                color="error"
                                onClick={() => resetTransactions()}
                            /> : null}
                    </div>
                    : <div style={{ padding: "1.5%" }}></div>}
                {alert ? (
                    <Alert
                        severity="warning"
                        sx={{
                            width: "15vw",
                            left: "55vw",
                            position: "absolute",
                            top: "35vh",
                            zIndex: "10",
                            border: "1px solid black",
                            borderRadius: "15px"
                        }}
                    >
                        <AlertTitle>Error</AlertTitle>
                        {message}
                    </Alert>
                ) : (
                    <></>
                )}
                {/* {showSearchVal ?
                    <h4>Showing Results For - <span>{searchVal}</span></h4>
                    : null} */}
            </div>

            {showLoader ?
                <div className="loaderIcon"><Loader /></div>
                :
                <>
                    {shift != "" ?
                        <div id="transData">
                            {tempTransactions.length > 0 ?
                                <>
                                    <h2>FOUND {tempTransactions.length} TRANSACTION(S)</h2>
                                    {tempTransactions.map(function (transaction, idx) {
                                        var textCol; transaction.bgColour == "" || !transaction.bgColour ? textCol = "black" : textCol = "white"
                                        var spanCol; transaction.bgColour == "" || !transaction.bgColour ? spanCol = "#2c53a0" : spanCol = "white"
                                        var newCol = false
                                        if (tempTransactions[idx + 1] && transaction.bgColour) {
                                            transaction.bgColour != tempTransactions[idx + 1].bgColour ? newCol = true : newCol = false;
                                        }
                                        return (
                                            <div key={idx} className="transaction">
                                                <Accordion sx={{ width: "100%", margin: "0 auto" }} >
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1-content"
                                                        id="transaction-header"
                                                        sx={{ fontSize: "1.2rem", color: textCol, backgroundColor: transaction.bgColour }}
                                                    >
                                                        <div className="transactionData">
                                                            <p><span style={{color: spanCol}} >Transaction ID:</span> {transaction.transactionId}</p>
                                                            <p><span style={{color: spanCol}} >Time</span>: {moment(transaction.transactionTime, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss")}</p>
                                                            <p><span style={{color: spanCol}} >Total</span>: £{transaction.totalAmount}</p>
                                                        </div>
                                                    </AccordionSummary>
                                                    <AccordionDetails sx={{ borderTop: "1px solid navy", width: "100%" }}>
                                                        <div className='transactionJournal itemTable'>
                                                            <p style={{ textAlign: "center", fontWeight: "bolder", textDecoration: "underline" }}>Items</p>
                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Name</th>
                                                                        <th>Price</th>
                                                                        <th>Qty</th>
                                                                        <th>Discount</th>
                                                                        <th>Refund</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {transaction.items.map((item, id) => {
                                                                        return (
                                                                            <tr key={id}>
                                                                                <td>{item.eposProductName}</td>
                                                                                <td>£{parseFloat(item.unitPrice).toFixed(2)}</td>
                                                                                <td>{item.quantity}</td>
                                                                                <td>
                                                                                    {item.discountAmount ?
                                                                                        <>£{parseFloat(item.discountAmount).toFixed(2)}</>
                                                                                        : <>-----</>
                                                                                    }
                                                                                    {item.discountName ?
                                                                                        <span style={{ fontSize: "0.7rem" }}> ({item.discountName})</span>
                                                                                        : null
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    {item.refundName ?
                                                                                        <span style={{ fontSize: "0.7rem" }}> ({item.refundName}- {item.refundDescription})</span>
                                                                                        : <>-----</>
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </table>

                                                            {transaction.miscItems.length > 0 ?
                                                                <div className="itemTable">
                                                                    <p style={{ textAlign: "center", fontWeight: "bolder", textDecoration: "underline" }}>Misc Items</p>
                                                                    <table>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Name</th>
                                                                                <th>Price</th>
                                                                                <th>Qty</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {transaction.miscItems.map((item, id) => {
                                                                                return (
                                                                                    <tr key={id}>
                                                                                        <td>{item.name}</td>
                                                                                        <td>£{parseFloat(item.unitPrice).toFixed(2)}</td>
                                                                                        <td>{item.quantity}</td>
                                                                                    </tr>
                                                                                )
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                : null}

                                                            {transaction.otherItems.length > 0 ?
                                                                <div className="itemTable">
                                                                    <p style={{ textAlign: "center", fontWeight: "bolder", textDecoration: "underline" }}>Other Items</p>
                                                                    <table>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Type</th>
                                                                                <th>Amount</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {transaction.otherItems.map((item, id) => {
                                                                                var txtCol; parseFloat(item.amount) < 0 ? txtCol = "red" : txtCol = "green"
                                                                                return (
                                                                                    <tr key={id}>
                                                                                        <td>{item.itemType}</td>
                                                                                        <td style={{ color: txtCol }}>£{parseFloat(item.amount).toFixed(2)}</td>
                                                                                    </tr>
                                                                                )
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                : null}
                                                            <hr />
                                                            <div style={{ display: "flex", justifyContent: "space-evenly", fontWeight: "bolder" }}>
                                                                <p>{transaction.tenderName} 
                                                                            {transaction.tenderName !=="SNAPPY DELIVERY" && transaction.tenderName !=="Cash"?
                                                                                <span style={{fontSize:"0.9rem"}}> - {transaction.actualTenderName}</span>
                                                                                :
                                                                                null
                                                                            }
                                                                    
                                                                </p>
                                                                <p>£{transaction.transactionTotal}</p>
                                                            </div>
                                                            <hr />
                                                                <p style={{marginBottom:"-1%",fontSize:"0.7rem", textDecoration:"none"}}>{transaction.barcode}</p>
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>
                                                {newCol ?
                                                    <hr />
                                                    : null}
                                            </div>
                                        )
                                    })}
                                </>
                                :
                                <div style={{ textAlign: "center", marginTop: "15%", color: "#000" }}>
                                    <ExploreOffIcon fontSize="large" color="primary" />
                                    <p>No Transactions Could Be Found For This Shift</p>
                                </div>
                            }
                        </div>
                        :
                        <div class='animation-example'>
                            <div class='item'>
                                <div class='line'></div>
                                <div class='dot'></div>
                                <div class='circle'></div>
                            </div>
                            <div class='item'>
                                <div class='line'></div>
                                <div class='dot'></div>
                                <div class='circle'></div>
                            </div>
                            <div class='item'>
                                <div class='line'></div>
                                <div class='dot'></div>
                                <div class='circle'></div>
                            </div>
                            <div class='item'>
                                <div class='line'></div>
                                <div class='dot'></div>
                                <div class='circle'></div>
                            </div>
                            <div class='item -type2'>
                                <div class='line'></div>
                                <div class='dot'></div>
                                <div class='circle'></div>
                            </div>
                            <div class='item -type2'>
                                <div class='line'></div>
                                <div class='dot'></div>
                                <div class='circle'></div>
                            </div>
                            <div class='item -type2'>
                                <div class='line'></div>
                                <div class='dot'></div>
                                <div class='circle'></div>
                            </div>
                            <div class='item -type2'>
                                <div class='line'></div>
                                <div class='dot'></div>
                                <div class='circle'></div>
                            </div>
                            <div class='center'>
                                <div class='circle'></div>
                                <div class='circle'></div>
                                <div class='circle'></div>
                            </div>
                        </div>
                    }
                </>
            }
        </>
    )
}