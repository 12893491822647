import React from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";

const options = ["Cancel order"];

const ITEM_HEIGHT = 48;

interface PurchaseOrderBtnsAdditionalProps {
  po: any;
  url: string | undefined;
  handleAlert: (message: string, severity: string) => void;
}

export default function PurchaseOrderBtnsAdditional({
  po,
  url,
  handleAlert,
}: PurchaseOrderBtnsAdditionalProps) {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isDialogShowed, setIsDialogShowed] = React.useState(false);
  
  const open = Boolean(anchorEl);

  let userD = localStorage.getItem("userDetails");
  let user = JSON.parse(userD!).user.user;
  //console.log(user);
  
  
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDialogToggle = () => {
    handleMenuClose();
    setIsDialogShowed(!isDialogShowed);
    
  };
  
    const handleCancelOrder = (): void => {
     let data = {
      poId: po.poId,
      staff: user,
      siteId: po.siteId,
      supplierId: po.supplierId,
      statusId: "5",
      admin: true ,
    };
      axios
        .post(`${url}/purchaseOrder/Save`, data)
        .then((res) => {
          handleAlert("Successfully Submitted", "success");
        })
        .catch((err) => {
          let errMsg = ``;
          console.log(err.response);
          if (err.response.data.includes("PO Cannot")) {
            errMsg = err.response.data;
          } else {
            errMsg = "Something went wrong - Please try again";
          }

          handleAlert(errMsg, "error");
        });
        handleDialogToggle();
        window.location.reload();
    };
 

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleMenuClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        slotProps={{
          paper: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
            },
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            disabled={po.statusName === "Pending" ? false : true}
            key={option}
            onClick={handleDialogToggle}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>

      <Dialog open={isDialogShowed} onClose={handleDialogToggle}>
        <DialogTitle>Confirm cancel</DialogTitle>
        <DialogContent>Do you want to cancel the purchase order?</DialogContent>
        <DialogActions>
          <Button onClick={handleCancelOrder}>Yes, cancel</Button>
          <Button onClick={handleDialogToggle}>No, go back</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
