import StoreNav from "../../StoreNav";
import Footer from "../../../Footer";
import axios from "axios";
import "./itSupport.scss";
import { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import ITdetails from "./ITdetails";
import ITform from "./ITform";

export function ITsystem() {
  const [site, setSite] = useState("");
  const [reload, setReload] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [errorAlert, setErrorAlert] = useState(false);
  const [tickets, setTickets] = useState([]);

  useEffect(() => {
    let getSite = localStorage.getItem("site");
    let localSite = getSite.replace(/['"]+/g, "");
    setSite(localSite);

    let data = {
      site: localSite,
    };
    axios
      .post(`https://nitrous.rt-itservices.co.uk/itSupport/getBySite`, data)
      .then((res) => {
        setTickets(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reload]);

  const rel = () => {
    setReload(!reload);
  };

  return (
    <main className="repair__home">
      {/* <StoreNav /> */}
      <section className="repairs">
        {successAlert ? (
          <Alert
            variant="filled"
            severity="success"
            sx={{
              position: "absolute",
              top: "30vh",
              width: "90vw",
              left: "5vw",
            }}
          >
            Repair updated
          </Alert>
        ) : (
          <></>
        )}
        {errorAlert ? (
          <Alert
            variant="filled"
            severity="error"
            sx={{
              position: "absolute",
              top: "30vh",
              width: "90vw",
              left: "5vw",
            }}
          >
            {message}
          </Alert>
        ) : (
          <></>
        )}
        <h1>IT Support</h1>
        <section className="it__forms">
          <ITform site={site} rel={rel} />
          {/* <ITdetails tickets={tickets} site={site} /> */}
        </section>
      </section>
      {/* <Footer /> */}
    </main>
  );
}
