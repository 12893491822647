import { useState, useEffect } from "react";
import axios from "axios";
import { Button } from "@mui/material";

export default function DriveOffReport() {
    const [siteList, setSiteList] = useState([]);
    const [site, setSite] = useState("");
    const [month, setMonth] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [total, setTotal] = useState(0);
    const [showTotal, setShowTotal] = useState(false);
    const [driveoffs, setDriveoffs] = useState([]);
    const url = "https://nitropos-backend.nitrosolutions.co.uk";

    useEffect(() => {
        axios
            .get("https://nitrous.rt-itservices.co.uk/sites/data")
            .then((res) => {
                setSiteList(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const handleReport = async () => {
        setShowTotal(false);
        let data = {
            site: site,
            start: startDate,
            end: endDate,
        };
        await axios
            .post(`${url}/driveoffs/site/date`, data)
            .then((res) => {
                setDriveoffs(res.data.driveoffs);
                setTotal(res.data.value);
                setShowTotal(true);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <>
            {" "}
            <h1 className="main-heading">Drive Off Reports</h1>
            <main className="global__report">
                <section className="report__row">
                    <select onChange={(e) => setSite(e.target.value)}>
                        <option>--Select Site--</option>
                        {siteList.map(function (d, i) {
                            return <option>{d.name}</option>;
                        })}
                    </select>
                    <input
                        type="date"
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                    <input
                        type="date"
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                </section>
                <Button
                    variant="outlined"
                    onClick={handleReport}
                    sx={{ margin: "2% auto" }}
                >
                    Get report
                </Button>

                {showTotal ? (
                    <section className="local_account">
                        <div className="report_row">
                            <label>Total Driveoff for {site}: </label>
                            <strong> {driveoffs.length}</strong>
                        </div>
                        <div className="report_row">
                            <label>Total amount: </label>
                            <strong> £{total}</strong>
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th>Fuel ID</th>
                                    <th>Date and Time</th>
                                    <th>Value</th>
                                    <th>Staff Name</th>
                                    <th>Current Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {driveoffs.map(function (d, i) {
                                    return (
                                        <tr>
                                            <th>{d.fuelId}</th>
                                            <th>{d.dateTime}</th>
                                            <th>£{d.amount}</th>
                                            <th>{d.staffName}</th>
                                            <th style={{ fontSize: "0.8rem" }}>
                                                {d.statusName}
                                            </th>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </section>
                ) : null}
            </main>
        </>
    );
}
