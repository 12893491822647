//MAIN IMPORTS
import "../products.scss";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import AuditLog from "./AuditLog";
import PriceHistory from "./PriceHistory";
import ProductHistory from "./ProductHistory";
import Loader from "../../../../animations/Loader";
import ArchiveProductModal from "../modal/ArchiveProductModal";
//MUI
import { Button } from "@mui/material";
//ICONS
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SendIcon from "@mui/icons-material/Send";
//ACCORDIAN
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
//DIALOG
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
//ALERTS
import { Alert } from "@mui/material";
//MODAL
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { getAllStores } from "../../../../utils/Api";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30vw",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
    "@media(max-width: 600px)": {
        width: "90vw",
        maxHeight: "40vh",
    },
    "@media(min-width: 601px) and (max-width: 900px)": {
        width: "45vw",
        maxHeight: "40vh",
    },
};

export default function ProductDetails(props) {
    //URLS
    const url = process.env.REACT_APP_DEPPLOYED;
    //USER
    const [userRole, setUserRole] = useState("");
    const [currentUser, setCurrentUser] = useState({});
    //REFS
    const selectRef = useRef();
    const selectRefTwo = useRef();
    const addSupRef = useRef();
    const orderCodeRef = useRef();
    const orderCodeRefTwo = useRef();
    //MASTER PRODUCT
    const [product, setProduct] = useState({});
    const [eposId, setEposId] = useState(0);
    //PRODUCT DETAILS
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [existingOrderCode, setExistingOrderCode] = useState("");
    const [orderCode, setOrderCode] = useState("");
    const [categoryId, setCategoryId] = useState(null);
    //BARCODE
    const [barcodes, setBarcodes] = useState([]);
    const [existingBarcodes, setExistingBarcodes] = useState();
    const [addBarcode, setAddBarcode] = useState("");
    //PRICING
    const [costPrice, setCostPrice] = useState("");
    const [vatInfo, setVatInfo] = useState({});
    const [salePriceIncludingTax, setSalePriceIncludingTax] = useState("");
    const [salePriceExcludingTax, setSalePriceExcludingTax] = useState("");
    const [vatList, setVatList] = useState([]);
    const [margin, setMargin] = useState("");
    const [profit, setProfit] = useState("");
    const [isPriceMarked, setIsPriceMarked] = useState(false);
    //SUPPLIERS
    const [mainSupplier, setMainSupplier] = useState(null);
    const [mainPackSize, setMainPackSize] = useState(null);
    const [mainSupplierCost, setMainSupplierCost] = useState(null);
    const [mainSupplierAuto, setMainSupplierAuto] = useState(false);
    const [mainSupplierForced, setMainSupplierForced] = useState(false);
    const [mainCaseBarcode, setMainCaseBarcode] = useState(null);
    const [additionalSuppliers, setAdditionalSuppliers] = useState([]);
    const [addedAdditionalSupplier, setAddedAdditionalSupplier] = useState("");
    const [additionalPackSize, setAdditionalPackSize] = useState(null);
    const [additionalSupplierCost, setAdditionalSupplierCost] = useState(null);
    const [additionalSupplierOrderCode, setAdditionalSupplierOrderCode] =
        useState([]);
    const [additionalSupplierCaseBarcode, setAdditionalSupplierCaseBarcode] =
        useState(null);
    const [additionalSupplierAuto, setAdditionalSupplierAuto] = useState(false);
    //AUDIT LOGS
    const [logs, setLogs] = useState([]);
    //PRICE HISTORY
    const [prices, setPrices] = useState([]);
    const [dates, setDates] = useState([]);
    //PRODUCT HISTOY
    const [productHistory, setProductHistory] = useState([]);

    //STOCK ADDITION MODAL
    const [showModal, setShowModal] = useState(false);
    const [showSiteStock, setShowSiteStock] = useState(false);
    const [siteList, setSiteList] = useState([]);
    const [stockData, setStockData] = useState([]);
    const [confirmingStock, setConfirmingStock] = useState(false);

    //PRODUCT DUPLICATION
    const [duplicateProduct, setDuplicateProduct] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);

    //ARCHIVE MODAL
    const [openArchive, setOpenArchive] = useState(false);
    const [archiveStockLevel, setArchiveStockLevel] = useState([]);

    //VALIDATION & ALERTS & LOADER & RELOAD
    const invalidNumberChars = ["-", "+", "e", "E"];
    const [validation, setValidation] = useState(false);
    const [validationList, setValidationList] = useState([]);
    const [barcodeAlert, setBarcodeAlert] = useState(false);
    const [barcodeMessage, setBarcodeMessage] = useState("");
    const [supplierAlert, setSupplierAlert] = useState(false);
    const [supplierMessage, setSupplierMessage] = useState("");
    const [submitError, setSubmitError] = useState(false);
    const [submitMessage, setSubmitMessage] = useState("");
    const [submitTitle, setSubmitTitle] = useState("");
    const [submitColor, setSubmitColor] = useState("");
    const [loader, setLoader] = useState(false);
    const [reload, setReload] = useState(false);
    const [noBarcodeCheck, setNoBarcodeCheck] = useState(false);

    useEffect(() => {
        //LOADER
        setLoader(true);
        if (props.addProduct) {
            setLoader(false);
        }
        //USER
        let userD = localStorage.getItem("userDetails");
        let user = JSON.parse(userD);

        setUserRole(user.user.role);
        setCurrentUser(user.user);

        const getManagerSites = async (sites) => {
            var finalSites = [];
            var managerSites = [];
            await axios
                .get("https://nitrous.rt-itservices.co.uk/sites/data")
                .then((res) => {
                    finalSites = res.data;
                })
                .catch((err) => {
                    console.log(err);
                });

            if (!sites.includes("HQ")) {
                for (let s = 0; s < finalSites.length; s++) {
                    if (sites.includes(finalSites[s].name)) {
                        managerSites.push(finalSites[s]);
                    }
                }
            } else {
                managerSites = finalSites;
            }
            setSiteList(managerSites);
        };

        //BRING IN VAT LIST
        axios
            .get(`${url}/eposSQL/getTax`)
            .then((res) => {
                setVatList(res.data);
                if (props.addProduct) {
                    setVatInfo(res.data[0]);
                }
            })
            .catch((err) => {
                console.log(err);
            });

        //BRING IN AUDIT LOGS
        // axios
        //     .get(`${url}/eposProducts/getProductHistory/${props.product}`)
        //     .then((res) => {
        //         setLogs(res.data);
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //     });
        //BRING IN PRICE HISTORY
        axios
            .get(`${url}/eposProducts/getProductPriceHistory/${props.product}`)
            .then((res) => {
                let changePrices = [];
                let changeDates = [];
                for (let p = 0; p < res.data.length; p++) {
                    changePrices.push(res.data[p].newValue);
                    changeDates.push(
                        new Date(res.data[p].changeDate).toDateString()
                    );
                }
                setPrices(changePrices);
                setDates(changeDates);
            })
            .catch((err) => {
                console.log(err);
            });

        //BRING IN PRODUCT HISTORY
        // axios
        //     .post(`${url}/stock/product/7dayhistory`, {
        //         eposId: props.product,
        //         sites: sites,
        //     })
        //     .then((res) => {
        //         setProductHistory(res.data);
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //     });

        //BRING IN PRODUCT INFO
        if (!props.addProduct) {
            axios
                .get(`${url}/eposProducts/${props.product}`)
                .then(async (res) => {
                    let data = res.data;
                    setIsUpdate(true);
                    let saleExTax = (
                        parseFloat(data.salePrice) /
                        (1 +
                            parseFloat(data.salePriceTaxGroup.percentage) / 100)
                    ).toFixed(2);

                    //SET PRODUCT DETAILS
                    setProduct(data);
                    setName(data.name);
                    setDescription(
                        data.description ? data.description : data.name
                    );
                    setOrderCode(data.orderCode);
                    setExistingOrderCode(data.orderCode);
                    setCategoryId(data.categoryId);

                    //SET BARCODE
                    setBarcodes(data.barcode);
                    setExistingBarcodes(data.barcode);

                    //SET PRICING

                    setCostPrice(data.costPrice);
                    setSalePriceIncludingTax(data.salePrice);
                    setSalePriceExcludingTax(saleExTax);
                    setVatInfo(data.salePriceTaxGroup);
                    setMargin(
                        (
                            ((saleExTax - parseFloat(data.costPrice)) /
                                saleExTax) *
                            100
                        ).toFixed(2)
                    );
                    setProfit(
                        (saleExTax - parseFloat(data.costPrice)).toFixed(2)
                    );

                    setIsPriceMarked(data.priceMarked);

                    //SET SUPPLIERS
                    if (data.mainSupplierDetails != null) {
                        setMainSupplier(data.supplierId);
                        setMainPackSize(data.mainSupplierDetails.caseSize);
                        setMainSupplierCost(
                            data.mainSupplierDetails.supplierCost
                        );
                        setMainCaseBarcode(
                            data.mainSupplierDetails.caseBarcode
                        );
                        setMainSupplierAuto(
                            data.mainSupplierDetails.autoOrdering
                        );
                        if (data.mainSupplierDetails.forcedOrder !== null) {
                            setMainSupplierForced(true);
                        } else {
                            setMainSupplierForced(false);
                        }
                    }
                    setAdditionalSuppliers(data.additionalSupplierDetails);
                    if (data.additionalSupplierDetails.length > 0) {
                        let addArray = data.additionalSupplierDetails.map(
                            (item, i) => {
                                return item.orderCode;
                            }
                        );
                        setAdditionalSupplierOrderCode(addArray);
                    }
                    //SET AUDIT LOGS
                    setLogs(data.logs);
                    setLoader(false);
                })
                .catch((err) => {
                    console.log(err);
                    setLoader(false);
                    props.setProductError(true);
                    props.setSelectedProduct(null);
                    props.setFoundProducts([]);
                });
        }

        getManagerSites(user.sites);
    }, [props.product, reload]);

    //RELOAD

    const rel = () => {
        setReload(!reload);
    };

    //VALIDATES NUMBER FIELDS
    const numberValidation = (e) => {
        if (invalidNumberChars.includes(e.key)) {
            e.preventDefault();
        }
    };

    //NAME CHANGE
    const handleName = (e) => {
        let newFormattedName = e.replace("'", "");
        setName(newFormattedName);
        setDescription(newFormattedName);
    };

    //BARCODE FUNCTIONS
    const handleAddBarcode = () => {
        //VALIDATE BARCODE
        if (
            addBarcode === "" ||
            addBarcode.includes("-") ||
            addBarcode.includes("e") ||
            addBarcode.includes("E") ||
            addBarcode.includes("+")
        ) {
            setBarcodeMessage("Barcode must be positive number");
            setBarcodeAlert(true);
            setTimeout(() => {
                setBarcodeAlert(false);
                setBarcodeMessage("");
            }, 4000);
        } else if (barcodes.includes(addBarcode)) {
            setBarcodeMessage("Barcode already in list");
            setBarcodeAlert(true);
            setTimeout(() => {
                setBarcodeAlert(false);
                setBarcodeMessage("");
            }, 4000);
        } else {
            //ADD BARCODE
            let newBarcodeArray = [...barcodes];
            newBarcodeArray.push(addBarcode);
            setBarcodes(newBarcodeArray);
            setAddBarcode("");
        }
    };

    //CHANGE BARCODE
    const handleEditBarcode = (e, i) => {
        let newBarcodeArray = [...barcodes];
        newBarcodeArray[i] = e;
        setBarcodes(newBarcodeArray);
    };
    //DELETE BARCODE
    const handleDeleteBarcode = (i) => {
        let filteredBarcodes = barcodes.filter((barcode, index) => {
            return i !== index;
        });
        setBarcodes(filteredBarcodes);
    };

    //SETS NEW VAT INFO
    const handleVatInfo = async (e) => {
        let foundVat = vatList.filter((item) => {
            return item.eposTaxGroupId === parseFloat(e);
        });

        setVatInfo(foundVat[0]);

        if (salePriceIncludingTax === "" || salePriceIncludingTax === "") {
            setSalePriceExcludingTax(0.0);
        } else {
            setSalePriceExcludingTax(
                (
                    parseFloat(salePriceIncludingTax) /
                    (1 + parseFloat(foundVat[0].percentage) / 100)
                ).toFixed(2)
            );
        }

        setMargin(
            (
                ((salePriceExcludingTax - parseFloat(costPrice)) /
                    salePriceExcludingTax) *
                100
            ).toFixed(2)
        );
        setProfit((salePriceExcludingTax - parseFloat(costPrice)).toFixed(2));
    };

    //DEALS WITH SALE PRICE CHANGES
    const handleSalePrice = (e) => {
        let saleEx = (e / (1 + parseFloat(vatInfo.percentage) / 100)).toFixed(
            2
        );

        setSalePriceExcludingTax(saleEx);

        setMargin(
            (((saleEx - parseFloat(costPrice)) / saleEx) * 100).toFixed(2)
        );
        setProfit((saleEx - parseFloat(costPrice)).toFixed(2));

        setSalePriceIncludingTax(e);
    };

    //CHANGE COST PRICE
    const handleCostPrice = (e) => {
        setCostPrice(e);
        setMargin(
            (
                ((salePriceExcludingTax - parseFloat(e)) /
                    salePriceExcludingTax) *
                100
            ).toFixed(2)
        );
        setProfit((salePriceExcludingTax - parseFloat(e)).toFixed(2));
    };

    //CHANGES TO ADDITONAL SUPPLIERS
    const handleAdditionalSupplierChange = async (e, field, index) => {
        let additionalSupCopy = additionalSuppliers[index];
        if (field === "supplier") {
            additionalSupCopy.supplierId = parseInt(e);
        }
        if (field === "pack") {
            additionalSupCopy.caseSize = e;
        }
        if (field === "cost") {
            additionalSupCopy.supplierCost = e;
        }
        if (field === "code") {
            additionalSupCopy.orderCode = e;
        }
        if (field === "barcode") {
            additionalSupCopy.caseBarcode = e;
        }
        if (field == "force") {
            var forcedOrder = null;
            if (e == true) {
                if (mainSupplierForced) {
                    await axios
                        .get(`${url}/eposProducts/active-stores`)
                        .then((resp) => {
                            console.log(resp.data);
                            forcedOrder = resp.data;
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            }
            additionalSupCopy.forcedOrder = forcedOrder;
        }

        let newArr = additionalSuppliers.map((sup, i) => {
            if (i === index) {
                return additionalSupCopy;
            } else {
                return sup;
            }
        });

        setAdditionalSuppliers(newArr);
    };

    //DELETE ADDITIONAL SUPPLIER
    const handleDeleteAddSupplier = (i) => {
        let filteredAdditionalSupplier = additionalSuppliers.filter(
            (supplier, index) => {
                return i !== index;
            }
        );

        setAdditionalSuppliers(filteredAdditionalSupplier);
    };

    //ADD ADDITIONAL SUPPLIER
    const handleAddAdditionalSupplier = () => {
        let supExists = additionalSuppliers.some((addSup, i) => {
            return addSup.supplierId === parseInt(addedAdditionalSupplier);
        });

        //VALIDATE SUPPLIER
        if (
            addedAdditionalSupplier === "" ||
            addedAdditionalSupplier === "Select Supplier"
        ) {
            setSupplierMessage("Additional Supplier Must have a name");
            setSupplierAlert(true);
            setTimeout(() => {
                setSupplierAlert(false);
                setSupplierMessage("");
            }, 4000);
        } else if (
            supExists ||
            mainSupplier === parseInt(addedAdditionalSupplier) ||
            mainSupplier === addedAdditionalSupplier
        ) {
            setSupplierMessage("Supplier already added");
            setSupplierAlert(true);
            setTimeout(() => {
                setSupplierAlert(false);
                setSupplierMessage("");
                addSupRef.current.value = "Select Supplier";
                setAdditionalPackSize("");
                setAdditionalSupplierCost("");
                setAdditionalSupplierAuto(false);
                setAdditionalSupplierOrderCode("");
                setAdditionalSupplierCaseBarcode("");
            }, 4000);
        } else {
            //ADD TO ARRAY
            let sup = {
                productId: props.addProduct ? null : product.productId,
                eposId: props.addProduct ? null : product.eposId,
                supplierId: parseInt(addedAdditionalSupplier),
                caseSize: parseInt(additionalPackSize),
                supplierCost: additionalSupplierCost,
                autoOrdering: additionalSupplierAuto,
                orderCode: additionalSupplierOrderCode,
                caseBarcode: additionalSupplierCaseBarcode,
            };
            let newAdditonalSuppliers = [...additionalSuppliers];
            newAdditonalSuppliers.push(sup);
            setAdditionalSuppliers(newAdditonalSuppliers);
            addSupRef.current.value = "Select Supplier";
            setAdditionalPackSize("");
            setAdditionalSupplierCost("");
            setAdditionalSupplierAuto(false);
            setAdditionalSupplierOrderCode("");
            setAdditionalSupplierCaseBarcode("");
        }
    };

    //MAIN VALIDATION
    const handleValidation = async (type, duplicate) => {
        setValidationList([]);
        let valList = [];

        //NAME
        if (name === "") {
            valList.push("Product Name");
        }
        //ORDER CODE
        if (
            (orderCode !== null || orderCode !== "") &&
            (mainSupplier === "" || mainSupplier === "Select Main Supplier")
        ) {
            valList.push("Can't have an order code without supplier name");
        } else if (
            (orderCode === null || orderCode === "") &&
            (mainSupplier === "187730" ||
                mainSupplier === "152170" ||
                mainSupplier === 187730 ||
                mainSupplier === 152170)
        ) {
            valList.push("Booker or Londis products must have an order code");
        } else if (orderCode !== null && orderCode.length > 50) {
            valList.push("Order code must be 50 characters or less");
        } else {
            await axios
                .post(`${url}/eposSQL/ordercode-supplier-check`, {
                    orderCode: orderCode,
                    supplierId: parseInt(mainSupplier),
                })
                .then((res) => {
                    if (!props.addProduct) {
                        if (
                            res.data.length > 0 &&
                            res.data[0].orderCode !== existingOrderCode
                        ) {
                            valList.push(
                                `Order code ${orderCode} exists for chosen supplier`
                            );
                        }
                    } else {
                        if (res.data.length > 0) {
                            valList.push(
                                `Order code ${orderCode} exists for chosen supplier`
                            );
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }

        //CATEGORY
        if (categoryId === null || categoryId === "Select Category") {
            valList.push("Category - select using dropdown");
        }

        if (mainCaseBarcode) {
            // CHECK IF MAIN SUPPLIER IS INSIDE OF CURRENT BCODES
            let existingBcodeExists = barcodes.some(
                (exBcode) =>
                    exBcode === mainCaseBarcode && mainCaseBarcode != null
            );

            if (existingBcodeExists) {
                valList.push(
                    `Case Barcode ${mainCaseBarcode} already exists in Barcodes`
                );
            } else {
                // DO BARCODE DB CHECK
                await axios
                    .post(`${url}/eposProducts/searchProduct`, {
                        barcode: mainCaseBarcode,
                    })
                    .then((res) => {
                        if (res.data.length > 0) {
                            let barcodeExists = res.data.some((item) => {
                                if (item.eposId != eposId) {
                                    return false;
                                } else {
                                    return (
                                        item.barcode === mainCaseBarcode ||
                                        item.caseBarcode === mainCaseBarcode
                                    );
                                }
                            });

                            if (props.addProduct) {
                                if (barcodeExists) {
                                    valList.push(
                                        `Case Barcode ${mainCaseBarcode} already exists`
                                    );
                                }
                            } else {
                                let barcodeInList = existingBarcodes.some(
                                    (item) => item === mainCaseBarcode
                                );
                                if (!barcodeInList && barcodeExists) {
                                    valList.push(
                                        `Case Barcode ${mainCaseBarcode} already exists`
                                    );
                                }
                            }
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }

        //BARCODES
        if (barcodes.length > 0) {
            for (let b = 0; b < barcodes.length; b++) {
                if (
                    barcodes[b] === "" &&
                    (mainSupplier === "187730" || mainSupplier === "152170")
                ) {
                    valList.push(
                        "Booker or Londis products must have a barcode"
                    );
                } else {
                    //CHECK IF BARCODE EXISTS
                    await axios
                        .post(`${url}/eposProducts/searchProduct`, {
                            barcode: barcodes[b],
                        })
                        .then((res) => {
                            if (res.data.length > 0) {
                                let barcodeExists = res.data.some((item) => {
                                    if (item.eposId != eposId) {
                                        return false;
                                    } else {
                                        return (
                                            item.barcode === barcodes[b] ||
                                            item.caseBarcode === barcodes[b]
                                        );
                                    }
                                });

                                if (props.addProduct) {
                                    if (barcodeExists) {
                                        valList.push(
                                            `Barcode ${barcodes[b]} already exists`
                                        );
                                    }
                                } else {
                                    let barcodeInList = existingBarcodes.some(
                                        (item) => item === barcodes[b]
                                    );
                                    if (!barcodeInList && barcodeExists) {
                                        valList.push(
                                            `Barcode ${barcodes[b]} already exists`
                                        );
                                    }
                                }
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            }
        }

        //PRICING
        if (costPrice === "") {
            valList.push(`Must have a cost price`);
        }
        if (salePriceIncludingTax === "") {
            valList.push(`Must have a sales price`);
        }

        //ADDITIONAL SUPPLIERS
        let addSupIds = additionalSuppliers.map((sup) => {
            return sup.supplierId;
        });

        addSupIds.push(parseInt(mainSupplier));

        let notDups = [];
        let dups = [];
        for (let i = 0; i < addSupIds.length; i++) {
            if (notDups.includes(addSupIds[i])) {
                dups.push(addSupIds[i]);
            } else {
                notDups.push(addSupIds[i]);
            }
        }

        if (dups.length > 0) {
            valList.push("Can not have duplicate additional suppliers");
        }

        let caseCheck = additionalSuppliers.some((sup) => {
            return (
                sup.supplierId === "" ||
                sup.supplierId === "Select Additional Supplier"
            );
        });
        let orderCheck = additionalSuppliers.some((sup) => {
            return (
                (sup.supplierId === "" ||
                    sup.supplierId === "Select Additional Supplier" ||
                    sup.supplierId === null) &&
                (sup.orderCode !== null || sup.orderCode !== "")
            );
        });

        if (caseCheck) {
            valList.push("Additonal suppliers - Must have name");
        }
        if (orderCheck) {
            valList.push(
                "Additonal suppliers - Can not have order code without supplier name"
            );
        }

        if (additionalSuppliers.length > 0) {
            for (let a = 0; a < additionalSuppliers.length; a++) {
                // CHECK ORDER CODE
                await axios
                    .post(`${url}/eposSQL/ordercode-supplier-check`, {
                        orderCode: parseInt(additionalSuppliers[a].orderCode),
                        supplierId: parseInt(additionalSuppliers[a].supplierId),
                    })
                    .then((res) => {
                        if (!props.addProduct) {
                            if (
                                res.data.length > 0 &&
                                !additionalSupplierOrderCode.includes(
                                    res.data[0].orderCode.toString()
                                )
                            ) {
                                valList.push(
                                    `Order code ${additionalSuppliers[a].orderCode} exists for additonal supplier`
                                );
                            }
                        } else {
                            if (res.data.length > 0) {
                                valList.push(
                                    `Order code ${additionalSuppliers[a].orderCode} exists for additonal supplier`
                                );
                            }
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });

                // CHECK BARCODE
                await axios
                    .post(`${url}/eposProducts/searchProduct`, {
                        barcode: additionalSuppliers[a].caseBarcode,
                    })
                    .then((res) => {
                        if (res.data.length > 0) {
                            let barcodeExists = res.data.some(
                                (item) =>
                                    item.barcode ===
                                        additionalSuppliers[a].caseBarcode ||
                                    item.caseBarcode ===
                                        additionalSuppliers[a].caseBarcode
                            );

                            if (props.addProduct) {
                                if (barcodeExists) {
                                    valList.push(
                                        `Case Barcode ${additionalSuppliers[a].caseBarcode} already exists`
                                    );
                                }
                            } else {
                                let barcodeInList = existingBarcodes.some(
                                    (item) =>
                                        item ===
                                        additionalSuppliers[a].caseBarcode
                                );
                                if (!barcodeInList && barcodeExists) {
                                    valList.push(
                                        `Case Barcode ${additionalSuppliers[a].caseBarcode} already exists`
                                    );
                                }
                            }
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }

        // setTimeout(() => {
        if (valList.length > 0) {
            setValidationList(valList);
            setValidation(true);
        } else {
            let isDupe = false;
            if (duplicate == "yes") {
                isDupe = true;
            }
            setDuplicateProduct(isDupe);
            let isUdate = false;
            if (type == "update") {
                isUdate = true;
            }
            setIsUpdate(isUdate);

            if (barcodes.length === 0) {
                setNoBarcodeCheck(true);
            } else {
                handleUpdate(isUdate, isDupe);
            }
        }
    };

    //FINAL UPDATE
    const handleUpdate = async (isUdate, isDupe) => {
        setLoader(true);
        setNoBarcodeCheck(false);
        let formattedBarcodes = barcodes.toString();

        let addSupIds = additionalSuppliers.map((sup) => {
            return sup.supplierId;
        });

        var forcedOrder = null;
        if (mainSupplierForced) {
            await axios
                .get(`${url}/eposProducts/active-stores`)
                .then((resp) => {
                    forcedOrder = resp.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        }

        let addSupCopy = [
            ...additionalSuppliers,
            {
                productId: product.productId,
                eposId: product.eposId,
                supplierId: mainSupplier,
                caseSize: mainPackSize,
                supplierCost: mainSupplierCost,
                autoOrdering: mainSupplierAuto,
                caseBarcode: mainCaseBarcode,
                forcedOrder: forcedOrder,
            },
        ];
        let data = {
            user: props.user,
            product: {
                productId: product.productId,
                eposId: product.eposId,
                name: name.trim(),
                description: description,
                costPrice: costPrice,
                salePrice: salePriceIncludingTax,
                eatOutPrice: salePriceIncludingTax,
                categoryId: categoryId,
                orderCode: orderCode,
                supplierId: mainSupplier,
                priceMarked: isPriceMarked,
                barcode: formattedBarcodes,
                salePriceTaxGroupId: vatInfo.eposTaxGroupId,
                eatOutPriceTaxGroupId: vatInfo.eposTaxGroupId,
                additionalSuppliersIds: addSupIds,
                additionalSupplierDetails: addSupCopy,
                isCostPriceIncTax: vatInfo.eposId !== 38841 ? true : false,
                isSalePriceIncTax: vatInfo.eposId !== 38841 ? true : false,
                isEatOutPriceIncTax: vatInfo.eposId !== 38841 ? true : false,
                // isArchived: false,
            },
        };

        setLoader(false);
        await axios
            .post(`${url}/eposProducts/update`, data)
            .then((res) => {
                setEposId(res.data.eposId);
                setLoader(false);

                if (isUdate) {
                    noStock(isUdate, isDupe);
                } else {
                    setShowModal(true);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoader(false);
                setSubmitTitle("SORRY UNABLE TO SEND");
                setSubmitColor("#bb0000");
                setSubmitMessage(
                    "Failed to submit. Please try again. If the problem persists then please contact IT. Thank You."
                );
                setSubmitError(true);
            });
    };

    const noStock = async (isUdate, isDupe) => {
        setShowModal(false);
        setSubmitTitle("SUCCESSFULLY SENT");
        setSubmitColor("#55ad26");
        setSubmitMessage("You have updated / created product");
        setSubmitError(true);
        setTimeout(() => {
            setSubmitError(false);
            if (isDupe || duplicateProduct) {
                resetModal();
                resetProductFields();
                resetHistory();
                scrollToTop();
            } else {
                window.location.reload();
            }
        }, 4000);
    };

    const stockUpdate = async (id, val) => {
        let _val = parseInt(val)
        let inArrayAlready = stockData.findIndex((item, i)=> 
            item.siteId == id
        );

        if(inArrayAlready === -1) {
            if(_val > 0) {
                setStockData([...stockData, {siteId: id, stock: _val}])
            }
        } else {
            if(_val === 0 || isNaN(_val)) {
                let _newStock = stockData;
                _newStock.splice(inArrayAlready, 1);
                setStockData([...stockData]);
            } else {
                let _newStock = stockData.map((item, i)=> {
                    if(item.siteId === id) {
                        return {
                            siteId: id,
                            stock: _val
                        }
                    } else {
                        return item;
                    }
                })
                setStockData([..._newStock])
            }
        } 
    };

    const confirmStock = async () => {
        setConfirmingStock(true);
        var data = {
            user: props.user,
            eposId: eposId,
            sites: stockData,
        };

        console.log(data);

        await axios
            .post(`${url}/eposProducts/addStock`, data)
            .then((res) => {
                setSubmitTitle("SUCCESSFULLY SENT");
                setSubmitColor("#55ad26");
                setSubmitMessage("Stock Has Now Been Applied!");
                setSubmitError(true);
                setTimeout(() => {
                    if (duplicateProduct) {
                        setSubmitError(false);
                        resetModal();
                        resetProductFields();
                        resetHistory();
                        scrollToTop();
                    } else {
                        window.location.reload();
                    }
                    setConfirmingStock(false)
                }, 2500);
            })
            .catch((err) => {
                console.log(err);
                setSubmitTitle("SORRY UNABLE TO SEND");
                setSubmitColor("#bb0000");
                setSubmitMessage(
                    "Failed to submit. Please try again. If the problem persists then please contact IT. Thank You."
                );
                setSubmitError(true);
                setTimeout(() => {
                    setSubmitError(false);
                    setConfirmingStock(false);
                }, 2000);
            });
    };

    const resetModal = async () => {
        setShowModal(false);
        setShowSiteStock(false);
        setConfirmingStock(false);
    };

    const resetProductFields = async () => {
        setProduct({});
        setEposId(0);
        setOrderCode("");
        setExistingOrderCode("");
        setBarcodes([]);
        setExistingBarcodes();
        setAddBarcode("");
        setMainCaseBarcode(null);
        setAdditionalSupplierOrderCode([]);
        setAdditionalSupplierCaseBarcode(null);
    };

    const resetHistory = async () => {
        setLogs([]);
        setPrices([]);
        setDates([]);
    };

    //Archive Attempt
    const handleArchiveAttempt = async () => { 
        await axios
            .get(`${url}/eposProducts/archive/precheck/${props.product}`)
            .then((res) => {
                setArchiveStockLevel(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
        setOpenArchive(true);
    };

    const scrollToTop = () => {
        var currentScroll =
            document.documentElement.scrollTop || document.body.scrollTop;
        if (currentScroll > 0) {
            window.requestAnimationFrame(scrollToTop);
            window.scrollTo(0, currentScroll - currentScroll / 5);
        }
    };

    return (
        <>
            {loader ? (
                <Loader></Loader>
            ) : (
                <section id="productTop" className="product_details">
                    {/* MAIN BUTTONS */}
                    {!props.addProduct && isUpdate && !duplicateProduct ? (
                        <div className="top_btns">
                            <Button
                                // id="add_prod_btn"
                                onClick={async () =>
                                    await handleValidation("update")
                                }
                                variant="contained"
                                startIcon={<SystemUpdateAltIcon />}
                                color="success"
                            >
                                Update
                            </Button>
                            <Button
                                onClick={async () =>
                                    await handleValidation("update", "yes")
                                }
                                variant="contained"
                                startIcon={<SystemUpdateAltIcon />}
                                color="success"
                            >
                                Update & Duplicate
                            </Button>
                        </div>
                    ) : (
                        <div className="top_btns">
                            <Button
                                onClick={async () =>
                                    await handleValidation("create")
                                }
                                variant="contained"
                                startIcon={<AddCircleIcon />}
                                color="success"
                            >
                                Create
                            </Button>
                            <Button
                                onClick={async () =>
                                    await handleValidation("create", "yes")
                                }
                                variant="contained"
                                startIcon={<AddCircleIcon />}
                                color="success"
                            >
                                Create & Duplicate
                            </Button>
                        </div>
                    )}

                    {/* PRODUCT DETAIL ACCORDIAN */}
                    <Accordion className="accordian" defaultExpanded>
                        <AccordionSummary
                            expandIcon={
                                <ExpandMoreIcon
                                    fontSize="large"
                                    sx={{ color: "#FFF" }}
                                />
                            }
                            aria-controls="panel1-content"
                            id="panel1-header"
                            className="accordian_header"
                        >
                            Product Details
                        </AccordionSummary>
                        <AccordionDetails className="accordian_details">
                            <section className="details">
                                <div className="detail_row">
                                    {!props.addProduct &&
                                    isUpdate &&
                                    !duplicateProduct ? (
                                        <div className="info_section epos_id">
                                            <label>EPOS ID</label>
                                            <input
                                                value={props.product}
                                                disabled
                                            />
                                        </div>
                                    ) : null}
                                    <div className="info_section name">
                                        <label>Name</label>
                                        <input
                                            value={name}
                                            placeholder={
                                                !name ? "Enter name" : null
                                            }
                                            onChange={(e) =>
                                                handleName(e.target.value)
                                            }
                                            maxLength={50}
                                        />
                                    </div>
                                    <div className="info_section description">
                                        <label>Description</label>
                                        <input
                                            value={description}
                                            placeholder={
                                                !description
                                                    ? "Enter description"
                                                    : null
                                            }
                                            maxLength={128}
                                        />
                                    </div>
                                </div>
                                <div className="detail_row">
                                    {" "}
                                    <div className="info_section category">
                                        <label>Category</label>
                                        <select
                                            value={categoryId}
                                            onChange={(e) =>
                                                setCategoryId(e.target.value)
                                            }
                                        >
                                            <option value={null}>
                                                Select Category
                                            </option>
                                            {props.categories.map(
                                                (category, i) => {
                                                    return (
                                                        <option
                                                            key={i}
                                                            value={
                                                                category.eposId
                                                            }
                                                            selected={
                                                                categoryId ===
                                                                category.eposId
                                                                    ? "selected"
                                                                    : null
                                                            }
                                                        >
                                                            {category.name}
                                                        </option>
                                                    );
                                                }
                                            )}
                                        </select>
                                    </div>
                                    <div className="info_section order_code">
                                        <label>Order Code</label>
                                        <input
                                            type="number"
                                            min="0"
                                            value={orderCode}
                                            placeholder={
                                                !orderCode
                                                    ? "Enter product code"
                                                    : null
                                            }
                                            onChange={(e) => {
                                                setOrderCode(e.target.value);
                                            }}
                                            onKeyDown={(e) =>
                                                numberValidation(e)
                                            }
                                            ref={orderCodeRef}
                                        />
                                    </div>
                                    <div className="info_section mainSupplier">
                                        <label>Main Supplier</label>
                                        <select
                                            value={mainSupplier}
                                            onChange={(e) => {
                                                setMainSupplier(e.target.value);
                                                selectRef.current.value =
                                                    e.target.value;
                                                selectRefTwo.current.value =
                                                    e.target.value;
                                            }}
                                            ref={selectRef}
                                        >
                                            <option value={null}>
                                                Select Main Supplier
                                            </option>
                                            {props.suppliers.map(
                                                (supplier, i) => {
                                                    return (
                                                        <option
                                                            key={i}
                                                            value={
                                                                supplier.eposId
                                                            }
                                                            selected={
                                                                mainSupplier ===
                                                                supplier.eposId
                                                                    ? "selected"
                                                                    : null
                                                            }
                                                        >
                                                            {supplier.name}
                                                        </option>
                                                    );
                                                }
                                            )}
                                        </select>
                                    </div>
                                </div>
                            </section>
                        </AccordionDetails>
                    </Accordion>
                    {/* BARCODE ACCORDIAN */}
                    <Accordion className="accordian">
                        <AccordionSummary
                            expandIcon={
                                <ExpandMoreIcon
                                    fontSize="large"
                                    sx={{ color: "#FFF" }}
                                />
                            }
                            aria-controls="panel1-content"
                            id="panel1-header"
                            className="accordian_header"
                        >
                            Barcode
                        </AccordionSummary>
                        <AccordionDetails className="accordian_details">
                            <section className="details">
                                <div className="detail_row">
                                    <div className="info_section barcode">
                                        <label>Barcode List</label>
                                        {barcodes.length > 0 ? (
                                            <table className="barcode_table">
                                                {barcodes.map((barcode, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    min="0"
                                                                    value={
                                                                        barcodes[
                                                                            i
                                                                        ]
                                                                    }
                                                                    placeholder="Enter barcode"
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleEditBarcode(
                                                                            e
                                                                                .target
                                                                                .value,
                                                                            i
                                                                        )
                                                                    }
                                                                    onKeyDown={(
                                                                        e
                                                                    ) => {
                                                                        if (
                                                                            e.key ===
                                                                            "Enter"
                                                                        ) {
                                                                            handleAddBarcode();
                                                                        }
                                                                        numberValidation(
                                                                            e
                                                                        );
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <Button
                                                                    variant="contained"
                                                                    color="error"
                                                                    onClick={() =>
                                                                        handleDeleteBarcode(
                                                                            i
                                                                        )
                                                                    }
                                                                >
                                                                    <DeleteOutlineIcon />
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </table>
                                        ) : (
                                            <p>No current barcodes</p>
                                        )}
                                    </div>

                                    <div className="info_section barcode">
                                        <label>Add Barcode</label>
                                        <table className="barcode_table">
                                            <tr className="inner_section">
                                                <td>
                                                    <input
                                                        type="number"
                                                        min="0"
                                                        placeholder="Enter barcode"
                                                        onChange={(e) =>
                                                            setAddBarcode(
                                                                e.target.value
                                                            )
                                                        }
                                                        value={addBarcode}
                                                        onKeyDown={(e) => {
                                                            if (
                                                                e.key ===
                                                                "Enter"
                                                            ) {
                                                                handleAddBarcode();
                                                            }
                                                            if (
                                                                invalidNumberChars.includes(
                                                                    e.key
                                                                )
                                                            ) {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <Button
                                                        color="primary"
                                                        variant="contained"
                                                        className="add_barcode_btn"
                                                        onClick={
                                                            handleAddBarcode
                                                        }
                                                    >
                                                        <AddCircleIcon />
                                                    </Button>
                                                </td>
                                            </tr>
                                        </table>
                                        {barcodeAlert ? (
                                            <Alert
                                                variant="filled"
                                                severity="warning"
                                                className="barcode_alert"
                                            >
                                                {barcodeMessage}
                                            </Alert>
                                        ) : null}
                                    </div>
                                </div>
                            </section>
                        </AccordionDetails>
                    </Accordion>
                    {/* PRICING ACCORDIAN */}
                    <Accordion className="accordian">
                        <AccordionSummary
                            expandIcon={
                                <ExpandMoreIcon
                                    fontSize="large"
                                    sx={{ color: "#FFF" }}
                                />
                            }
                            aria-controls="panel1-content"
                            id="panel1-header"
                            className="accordian_header"
                        >
                            Pricing
                        </AccordionSummary>
                        <AccordionDetails className="accordian_details">
                            <section className="details">
                                <div className="detail_row">
                                    <div className="info_section cost_price">
                                        <label>
                                            Cost Price{" "}
                                            <span className="small_label">
                                                (£)
                                            </span>
                                        </label>
                                        <input
                                            type="number"
                                            min="0"
                                            value={costPrice}
                                            placeholder="Enter cost price"
                                            onChange={(e) =>
                                                handleCostPrice(e.target.value)
                                            }
                                            onKeyDown={(e) =>
                                                numberValidation(e)
                                            }
                                        />
                                    </div>
                                    <div className="info_section sale_price_exc">
                                        <label>
                                            Sale Price{" "}
                                            <span className="small_label">
                                                (£) Exc.Tax
                                            </span>
                                        </label>
                                        <input
                                            value={
                                                salePriceIncludingTax !== null
                                                    ? salePriceExcludingTax
                                                    : "0:00"
                                            }
                                            disabled
                                        />
                                    </div>
                                    <div className="info_section vat">
                                        <label>VAT</label>
                                        <select
                                            onChange={(e) =>
                                                handleVatInfo(e.target.value)
                                            }
                                            disabled={isPriceMarked}
                                        >
                                            {vatList.map((item, i) => {
                                                return (
                                                    <option
                                                        key={i}
                                                        value={
                                                            item.eposTaxGroupId
                                                        }
                                                        selected={
                                                            vatInfo.eposTaxGroupId ===
                                                            item.eposTaxGroupId
                                                                ? "selected"
                                                                : null
                                                        }
                                                    >
                                                        {item.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    <div className="info_section sale_price_inc">
                                        <label>
                                            Sale Price{" "}
                                            <span className="small_label">
                                                (£) Inc.Tax
                                            </span>
                                        </label>
                                        <input
                                            type="number"
                                            min="0"
                                            value={salePriceIncludingTax}
                                            placeholder="Enter sale price"
                                            onChange={(e) =>
                                                handleSalePrice(e.target.value)
                                            }
                                            onKeyDown={(e) =>
                                                numberValidation(e)
                                            }
                                            disabled={isPriceMarked}
                                        />
                                    </div>
                                </div>
                                <div className="detail_row">
                                    <div className="info_section margin">
                                        <label>
                                            Margin{" "}
                                            <span className="small_label">
                                                (%)
                                            </span>
                                        </label>
                                        <input
                                            type="number"
                                            value={margin}
                                            disabled
                                        />
                                    </div>
                                    <div className="info_section profit">
                                        <label>
                                            Profit{" "}
                                            <span className="small_label">
                                                (£)
                                            </span>
                                        </label>
                                        <input
                                            type="number"
                                            value={profit}
                                            disabled
                                        />
                                    </div>
                                    <div className="info_section price_marked">
                                        <label>Price Marked</label>
                                        <input
                                            id="price_marked_checkbox"
                                            type="checkbox"
                                            checked={isPriceMarked}
                                            onChange={() =>
                                                setIsPriceMarked(!isPriceMarked)
                                            }
                                        />
                                    </div>
                                </div>
                            </section>
                        </AccordionDetails>
                    </Accordion>
                    {/* SUPPLIER INFORMATION ACCORDIAN */}
                    {/* //!AUTO ORDERING CURRENTLY DISABLE - TO BE CHANGED WHEN AUTO ORDERING READY */}
                    <Accordion className="accordian">
                        <AccordionSummary
                            expandIcon={
                                <ExpandMoreIcon
                                    fontSize="large"
                                    sx={{ color: "#FFF" }}
                                />
                            }
                            aria-controls="panel1-content"
                            id="panel1-header"
                            className="accordian_header"
                        >
                            Supplier Information
                        </AccordionSummary>
                        <AccordionDetails className="accordian_details">
                            <section className="details">
                                <table className="supplier_table">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Case Size</th>
                                            <th>Case Barcode</th>
                                            <th>
                                                Supplier Cost
                                                <span className="small_label">
                                                    {" "}
                                                    (£)
                                                </span>
                                            </th>
                                            <th>Order Code</th>
                                            <th>Force Order</th>
                                            <th>Auto Ordering (TBC)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            className="main_supplier_row"
                                            style={{
                                                backgroundColor:
                                                    "rgb(208, 247, 222)",
                                            }}
                                        >
                                            <td data-label="Name">
                                                <select
                                                    value={mainSupplier}
                                                    onChange={(e) => {
                                                        setMainSupplier(
                                                            e.target.value
                                                        );
                                                        selectRef.current.value =
                                                            e.target.value;
                                                        selectRefTwo.current.value =
                                                            e.target.value;
                                                    }}
                                                    className="supplier_name"
                                                    ref={selectRefTwo}
                                                >
                                                    <option value={null}>
                                                        Select Main Supplier
                                                    </option>
                                                    {props.suppliers.map(
                                                        (supplier, i) => {
                                                            return (
                                                                <option
                                                                    key={i}
                                                                    value={
                                                                        supplier.eposId
                                                                    }
                                                                    selected={
                                                                        mainSupplier ===
                                                                        supplier.eposId
                                                                            ? "selected"
                                                                            : null
                                                                    }
                                                                >
                                                                    {
                                                                        supplier.name
                                                                    }
                                                                </option>
                                                            );
                                                        }
                                                    )}
                                                </select>
                                            </td>
                                            <td data-label="Case Size">
                                                <input
                                                    type="number"
                                                    min="0"
                                                    className="supplier_case"
                                                    value={mainPackSize}
                                                    placeholder="Enter case size"
                                                    onChange={(e) => {
                                                        setMainPackSize(
                                                            e.target.value
                                                        );
                                                    }}
                                                    onKeyDown={(e) =>
                                                        numberValidation(e)
                                                    }
                                                />
                                            </td>
                                            <td data-label="Case Barcode">
                                                <input
                                                    type="text"
                                                    placeholder="Enter case barcode"
                                                    className="supplier_case_barcode"
                                                    value={mainCaseBarcode}
                                                    onChange={(e) => {
                                                        setMainCaseBarcode(
                                                            e.target.value
                                                        );
                                                    }}
                                                    onKeyDown={(e) =>
                                                        numberValidation(e)
                                                    }
                                                />
                                            </td>
                                            <td data-label="Supplier Cost">
                                                {" "}
                                                <input
                                                    type="number"
                                                    min="0"
                                                    className="supplier_cost"
                                                    value={mainSupplierCost}
                                                    placeholder="Enter cost"
                                                    onChange={(e) => {
                                                        setMainSupplierCost(
                                                            e.target.value
                                                        );
                                                    }}
                                                    onKeyDown={(e) =>
                                                        numberValidation(e)
                                                    }
                                                />
                                            </td>
                                            <td data-label="Order Code">
                                                {" "}
                                                <input
                                                    type="number"
                                                    min="0"
                                                    className="order_co"
                                                    value={orderCode}
                                                    placeholder="Enter order code"
                                                    onChange={(e) => {
                                                        setOrderCode(
                                                            e.target.value
                                                        );
                                                        orderCodeRef.current.value =
                                                            e.target.value;
                                                        orderCodeRefTwo.current.value =
                                                            e.target.value;
                                                    }}
                                                    onKeyDown={(e) =>
                                                        numberValidation(e)
                                                    }
                                                    ref={orderCodeRefTwo}
                                                />
                                            </td>
                                            <td data-label="Forced Ordering">
                                                <input
                                                    className="supplier_auto"
                                                    type="checkbox"
                                                    checked={mainSupplierForced}
                                                    onClick={() =>
                                                        setMainSupplierForced(
                                                            !mainSupplierForced
                                                        )
                                                    }
                                                />
                                            </td>
                                            <td data-label="Auto ordering (TBC)">
                                                <input
                                                    disabled
                                                    type="checkbox"
                                                    className="supplier_auto"
                                                    checked={false}
                                                    onClick={() =>
                                                        setMainSupplierAuto(
                                                            !mainSupplierAuto
                                                        )
                                                    }
                                                />
                                            </td>
                                        </tr>

                                        {/* MAP ADDITONAL SUPPLIERS */}
                                        {additionalSuppliers.length > 0
                                            ? additionalSuppliers.map(
                                                  (additionalSupplier, i) => {
                                                      return (
                                                          <tr key={i}>
                                                              <td data-label="Name">
                                                                  <select
                                                                      onChange={(
                                                                          e
                                                                      ) =>
                                                                          handleAdditionalSupplierChange(
                                                                              e
                                                                                  .target
                                                                                  .value,
                                                                              "supplier",
                                                                              i
                                                                          )
                                                                      }
                                                                  >
                                                                      <option
                                                                          value={
                                                                              null
                                                                          }
                                                                      >
                                                                          Select
                                                                          Additional
                                                                          Supplier
                                                                      </option>
                                                                      {props.suppliers.map(
                                                                          (
                                                                              supplier,
                                                                              i2
                                                                          ) => {
                                                                              return (
                                                                                  <option
                                                                                      key={
                                                                                          i2
                                                                                      }
                                                                                      value={
                                                                                          supplier.eposId
                                                                                      }
                                                                                      selected={
                                                                                          additionalSupplier.supplierId ===
                                                                                          supplier.eposId
                                                                                              ? "selected"
                                                                                              : null
                                                                                      }
                                                                                  >
                                                                                      {
                                                                                          supplier.name
                                                                                      }
                                                                                  </option>
                                                                              );
                                                                          }
                                                                      )}
                                                                  </select>
                                                              </td>
                                                              <td data-label="Case Size">
                                                                  <input
                                                                      type="number"
                                                                      min="0"
                                                                      className="supplier_case"
                                                                      placeholder="Enter case size"
                                                                      value={
                                                                          additionalSupplier.caseSize
                                                                      }
                                                                      onChange={(
                                                                          e
                                                                      ) =>
                                                                          handleAdditionalSupplierChange(
                                                                              e
                                                                                  .target
                                                                                  .value,
                                                                              "pack",
                                                                              i
                                                                          )
                                                                      }
                                                                      onKeyDown={(
                                                                          e
                                                                      ) =>
                                                                          numberValidation(
                                                                              e
                                                                          )
                                                                      }
                                                                  />
                                                              </td>
                                                              <td data-label="Case Barcode">
                                                                  <input
                                                                      type="text"
                                                                      min="0"
                                                                      className="supplier_case"
                                                                      placeholder="Enter case barcode"
                                                                      value={
                                                                          additionalSupplier.caseBarcode
                                                                      }
                                                                      onChange={(
                                                                          e
                                                                      ) =>
                                                                          handleAdditionalSupplierChange(
                                                                              e
                                                                                  .target
                                                                                  .value,
                                                                              "barcode",
                                                                              i
                                                                          )
                                                                      }
                                                                  />
                                                              </td>
                                                              <td data-label="Supplier Cost">
                                                                  {" "}
                                                                  <input
                                                                      type="number"
                                                                      min="0"
                                                                      className="supplier_cost"
                                                                      placeholder="Enter cost"
                                                                      value={
                                                                          additionalSupplier.supplierCost
                                                                      }
                                                                      onChange={(
                                                                          e
                                                                      ) =>
                                                                          handleAdditionalSupplierChange(
                                                                              e
                                                                                  .target
                                                                                  .value,
                                                                              "cost",
                                                                              i
                                                                          )
                                                                      }
                                                                      onKeyDown={(
                                                                          e
                                                                      ) =>
                                                                          numberValidation(
                                                                              e
                                                                          )
                                                                      }
                                                                  />
                                                              </td>
                                                              <td data-label="Order Code">
                                                                  {" "}
                                                                  <input
                                                                      type="number"
                                                                      min="0"
                                                                      className="order_co"
                                                                      placeholder="Enter order code"
                                                                      value={
                                                                          additionalSupplier.orderCode
                                                                      }
                                                                      onChange={(
                                                                          e
                                                                      ) =>
                                                                          handleAdditionalSupplierChange(
                                                                              e
                                                                                  .target
                                                                                  .value,
                                                                              "code",
                                                                              i
                                                                          )
                                                                      }
                                                                      onKeyDown={(
                                                                          e
                                                                      ) =>
                                                                          numberValidation(
                                                                              e
                                                                          )
                                                                      }
                                                                  />
                                                              </td>
                                                              <td data-label="force order">
                                                                  <input
                                                                      className="supplier_auto"
                                                                      type="checkbox"
                                                                      value={
                                                                          additionalSupplier.forcedOrder
                                                                      }
                                                                      onClick={(
                                                                          e
                                                                      ) =>
                                                                          handleAdditionalSupplierChange(
                                                                              e
                                                                                  .target
                                                                                  .value,
                                                                              "force",
                                                                              i
                                                                          )
                                                                      }
                                                                  />
                                                              </td>
                                                              {/* <td data-label="Auto Ordering - Coming soon">
                                                                <input
                                                                    disabled
                                                                    className="supplier_auto"
                                                                    type="checkbox"
                                                                    default={
                                                                        additionalSupplier.autoOrdering
                                                                    }
                                                                    value="on"
                                                                    onClick={(
                                                                        e
                                                                    ) =>
                                                                        handleAdditionalSupplierChange(
                                                                            e
                                                                                .target
                                                                                .value,
                                                                            "auto",
                                                                            i
                                                                        )
                                                                    }
                                                                />
                                                            </td> */}
                                                              <td>
                                                                  <Button
                                                                      id="delete_sup"
                                                                      variant="contained"
                                                                      color="error"
                                                                      style={{
                                                                          backgroundColor:
                                                                              "#d32f2f",
                                                                          width: "10%",
                                                                      }}
                                                                      onClick={() =>
                                                                          handleDeleteAddSupplier(
                                                                              i
                                                                          )
                                                                      }
                                                                  >
                                                                      <DeleteOutlineIcon />
                                                                  </Button>
                                                              </td>
                                                          </tr>
                                                      );
                                                  }
                                              )
                                            : null}
                                    </tbody>
                                </table>
                                {/* ADD NEW ADDITIONAL SUPPLIER */}
                                <h3>Add Additional Supplier</h3>
                                <table className="add_supplier_table">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Case Size</th>
                                            <th>Case Barcode</th>
                                            <th>
                                                Supplier Cost{" "}
                                                <span className="small_label">
                                                    (£)
                                                </span>
                                            </th>
                                            <th>Order Code</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td data-label="Name">
                                                <select
                                                    onChange={(e) =>
                                                        setAddedAdditionalSupplier(
                                                            e.target.value
                                                        )
                                                    }
                                                    ref={addSupRef}
                                                >
                                                    <option value={null}>
                                                        Select Supplier
                                                    </option>
                                                    {props.suppliers.map(
                                                        (supplier, i) => {
                                                            return (
                                                                <option
                                                                    key={i}
                                                                    value={
                                                                        supplier.eposId
                                                                    }
                                                                >
                                                                    {
                                                                        supplier.name
                                                                    }
                                                                </option>
                                                            );
                                                        }
                                                    )}
                                                </select>
                                            </td>
                                            <td data-label="Case Size">
                                                <input
                                                    type="number"
                                                    min="0"
                                                    className="supplier_case"
                                                    value={additionalPackSize}
                                                    placeholder="Enter case size"
                                                    onChange={(e) =>
                                                        setAdditionalPackSize(
                                                            e.target.value
                                                        )
                                                    }
                                                    onKeyDown={(e) =>
                                                        numberValidation(e)
                                                    }
                                                />
                                            </td>

                                            <td data-label="Case Barcode">
                                                <input
                                                    type="text"
                                                    className="supplier_case"
                                                    placeholder="Enter case barcode"
                                                    value={
                                                        additionalSupplierCaseBarcode
                                                    }
                                                    onChange={(e) =>
                                                        setAdditionalSupplierCaseBarcode(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </td>
                                            <td data-label="Supplier Cost">
                                                <input
                                                    type="number"
                                                    min="0"
                                                    className="supplier_cost"
                                                    value={
                                                        additionalSupplierCost
                                                    }
                                                    placeholder="Enter cost"
                                                    onChange={(e) =>
                                                        setAdditionalSupplierCost(
                                                            e.target.value
                                                        )
                                                    }
                                                    onKeyDown={(e) =>
                                                        numberValidation(e)
                                                    }
                                                />
                                            </td>
                                            <td data-label="Order Code">
                                                <input
                                                    type="number"
                                                    min="0"
                                                    className="order_co"
                                                    value={
                                                        additionalSupplierOrderCode
                                                    }
                                                    placeholder="Enter order code"
                                                    onChange={(e) =>
                                                        setAdditionalSupplierOrderCode(
                                                            e.target.value
                                                        )
                                                    }
                                                    onKeyDown={(e) =>
                                                        numberValidation(e)
                                                    }
                                                />
                                            </td>

                                            <td>
                                                <Button
                                                    id="add_sup"
                                                    style={{
                                                        backgroundColor:
                                                            "#1976D2",
                                                        width: "10%",
                                                        color: "#fff",
                                                    }}
                                                    onClick={
                                                        handleAddAdditionalSupplier
                                                    }
                                                >
                                                    <AddCircleIcon />
                                                </Button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                {supplierAlert ? (
                                    <Alert
                                        variant="filled"
                                        severity="warning"
                                        className="supplier_alert"
                                    >
                                        {supplierMessage}
                                    </Alert>
                                ) : null}
                            </section>
                        </AccordionDetails>
                    </Accordion>
                    {/* PRICE HISTORY ACCORDIAN */}
                    <Accordion className="accordian">
                        <AccordionSummary
                            expandIcon={
                                <ExpandMoreIcon
                                    fontSize="large"
                                    sx={{ color: "#FFF" }}
                                />
                            }
                            aria-controls="panel1-content"
                            id="panel1-header"
                            className="accordian_header"
                        >
                            Price History
                        </AccordionSummary>
                        <AccordionDetails className="accordian_details">
                            {prices.length > 0 ? (
                                <PriceHistory prices={prices} dates={dates} />
                            ) : (
                                <h3>NO PRICE HISTORY</h3>
                            )}
                        </AccordionDetails>
                    </Accordion>
                    {/* PRODUCT HISTORY ACCORDIAN */}

                    <Accordion className="accordian">
                        <AccordionSummary
                            expandIcon={
                                <ExpandMoreIcon
                                    fontSize="large"
                                    sx={{ color: "#FFF" }}
                                />
                            }
                            aria-controls="panel1-content"
                            id="panel1-header"
                            className="accordian_header"
                        >
                            7-Day Product History
                        </AccordionSummary>
                        <AccordionDetails className="accordian_details">
                            {/* //!TO BE ADDED WHEN READY */}
                            {/* {productHistory.length > 0 ? (
                                <ProductHistory
                                    productHistory={productHistory}
                                />
                            ) : (
                                <h3>NO PRODUCT HISTORY</h3>
                            )} */}
                            <h3>COMING SOON</h3>
                        </AccordionDetails>
                    </Accordion>
                    {/* AUDIT LOG ACCORDIAN */}
                    <Accordion className="accordian">
                        <AccordionSummary
                            expandIcon={
                                <ExpandMoreIcon
                                    fontSize="large"
                                    sx={{ color: "#FFF" }}
                                />
                            }
                            aria-controls="panel1-content"
                            id="panel1-header"
                            className="accordian_header"
                        >
                            Audit Logs
                        </AccordionSummary>
                        <AccordionDetails className="accordian_details">
                            {logs.length > 0 ? (
                                <AuditLog logs={logs} />
                            ) : (
                                <h3>NO LOGS</h3>
                            )}
                        </AccordionDetails>
                    </Accordion>
                    <section id="add_prod_btn_bottom">
                        {!props.addProduct && isUpdate && !duplicateProduct ? (
                            <section className="bottom_btns">
                                <Button
                                    onClick={async () =>
                                        await handleValidation("update")
                                    }
                                    variant="contained"
                                    startIcon={<SystemUpdateAltIcon />}
                                    color="success"
                                >
                                    Update
                                </Button>
                                <Button
                                    onClick={async () =>
                                        await handleValidation("update", "yes")
                                    }
                                    variant="contained"
                                    startIcon={<SystemUpdateAltIcon />}
                                    color="success"
                                    sx={{ width: "100%" }}
                                >
                                    Update & Duplicate
                                </Button>
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={handleArchiveAttempt}
                                >
                                    Archive
                                </Button>
                            </section>
                        ) : (
                            <section className="bottom_btns">
                                <Button
                                    onClick={async () =>
                                        await handleValidation("create")
                                    }
                                    variant="contained"
                                    startIcon={<AddCircleIcon />}
                                    color="success"
                                >
                                    Create
                                </Button>
                                <Button
                                    onClick={async () =>
                                        await handleValidation("create", "yes")
                                    }
                                    variant="contained"
                                    startIcon={<AddCircleIcon />}
                                    color="success"
                                >
                                    Create & Duplicate
                                </Button>
                            </section>
                        )}
                    </section>
                    {/* VALIDATION POPUP */}
                    <Dialog
                        onClose={() => setValidation(false)}
                        open={validation}
                        fullWidth={"60vw"}
                    >
                        <DialogTitle color="error">REQUIRED FIELDS</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Please complete the following required fields
                                before proceeding:
                            </DialogContentText>

                            <List sx={{ pt: 0 }}>
                                {validationList.map((v, i) => {
                                    return (
                                        <ListItem key={i}>
                                            <ListItemIcon>
                                                <SendIcon />
                                            </ListItemIcon>
                                            <ListItemText>{v}</ListItemText>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </DialogContent>
                    </Dialog>
                    {/* NO BARCODE CHECK POPUP */}
                    <Dialog open={noBarcodeCheck} fullWidth={"50vw"}>
                        <DialogTitle color="error">BARCODE CHECK</DialogTitle>
                        <DialogContent>
                            <DialogContentText sx={{ textAlign: "center" }}>
                                You have not included any barcodes with this
                                product. Are you positive this is correct?{" "}
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-evenly",
                                        marginTop: "2vh",
                                    }}
                                >
                                    <Button
                                        onClick={() =>
                                            handleUpdate(
                                                isUpdate,
                                                duplicateProduct
                                            )
                                        }
                                        variant="contained"
                                        color="success"
                                        sx={{ width: "45%" }}
                                    >
                                        Yes
                                    </Button>
                                    <Button
                                        onClick={() => setNoBarcodeCheck(false)}
                                        variant="contained"
                                        color="error"
                                        sx={{ width: "40%" }}
                                    >
                                        No
                                    </Button>
                                </div>
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                    {/* SUBMIT ERROR POPUP */}
                    <Dialog
                        onClose={() => {
                            if (submitColor === "#bb0000") {
                                setSubmitError(false);
                            }
                        }}
                        open={submitError}
                        fullWidth={"60vw"}
                    >
                        <DialogTitle color={submitColor}>
                            {submitTitle}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText sx={{ textAlign: "center" }}>
                                {submitMessage}
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>

                    {/* SHOW STOCK ADDITION MODAL */}
                    <Modal
                        open={showModal}
                        aria-labelledby="stock-addition"
                        aria-describedby="add-stock-to-product"
                    >
                        <Box sx={style}>
                            {showSiteStock ? (
                                <div id="stockAdd">
                                    <Typography
                                        id="stock-addition"
                                        variant="h6"
                                        component="h2"
                                    >
                                        Enter Site Stock Levels Below -
                                    </Typography>

                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Site</th>
                                                <th>Stock</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {siteList.map(function (d, i) {
                                                var idx = stockData.findIndex(
                                                    (s) => s.siteId == d.epos
                                                );
                                                var stock;
                                                idx >= 0
                                                    ? (stock = parseInt(
                                                          stockData[idx].stock
                                                      ))
                                                    : (stock = "");
                                                return (
                                                    <tr>
                                                        <td>{d.name}</td>
                                                        <td>
                                                            <input
                                                                type="number"
                                                                min={0}
                                                                // value={stock}
                                                                onChange={(e) =>
                                                                    stockUpdate(
                                                                        d.epos,
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            <tr>
                                                <td>Warehouse</td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        min={0}
                                                        // value={stock}
                                                        onChange={(e) =>
                                                            stockUpdate(
                                                                24753,
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    {confirmingStock ? (
                                        <Loader />
                                    ) : (
                                        <section className="btn-section">
                                            <Button
                                                variant="contained"
                                                color="error"
                                                onClick={() => {
                                                    noStock();
                                                }}
                                            >
                                                NO STOCK
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="success"
                                                onClick={() => {
                                                    confirmStock();
                                                }}
                                            >
                                                ADD STOCK
                                            </Button>
                                        </section>
                                    )}
                                </div>
                            ) : (
                                <>
                                    <Typography
                                        id="stock-addition"
                                        variant="h6"
                                        component="h2"
                                    >
                                        Would You Like To Put This Product Into
                                        Stock?
                                    </Typography>
                                    <Typography
                                        id="modal-modal-description"
                                        sx={{ mt: 2 }}
                                    >
                                        <Stack
                                            spacing={15}
                                            direction="row"
                                            sx={{
                                                width: "50%",
                                                margin: "auto",
                                            }}
                                        >
                                            <Button
                                                variant="contained"
                                                color="error"
                                                onClick={() => {
                                                    noStock();
                                                }}
                                            >
                                                NO
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="success"
                                                onClick={() => {
                                                    setShowSiteStock(true);
                                                }}
                                            >
                                                YES
                                            </Button>
                                        </Stack>
                                    </Typography>
                                </>
                            )}
                        </Box>
                    </Modal>
                </section>
            )}
            {/* ARCHIVE MODAL */}
            <Modal open={openArchive}>
                <ArchiveProductModal
                    setOpenArchive={setOpenArchive}
                    stock={archiveStockLevel}
                    productId={props.product}
                    rel={rel}
                    user={currentUser}
                    search={props.handleSearch}
                />
            </Modal>
        </>
    );
}
