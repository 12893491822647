//Components
import PurchaseOrderRow from "./PurchaseOrderRow";
//Styling
import "./purchase_orders.scss";
//Props
interface Props {
    orderLines: any;
    handleUpdateOrderLines: (
        product: number,
        actual: number,
        units: string
    ) => void;
    isSiteLogin: boolean;
    handleAlert: (message: string, severity: string, duration: number) => void;
    handleUnsave: (product: number) => void;
    adminSide?: boolean;
    approvalPo?: boolean;
    supplier?:number;
}

export default function PurchaseOrderTable({
    orderLines,
    handleUpdateOrderLines,
    isSiteLogin,
    handleAlert,
    handleUnsave,
    adminSide,
    approvalPo,
    supplier
}: Props) {
    var prevName = "";

    return (
        <table
            className={
                !adminSide
                    ? "purchase_order_table"
                    : "purchase_order_table_warehouse"
            }
        >
            <tbody>
                {orderLines &&
                    orderLines.map((ol: any, i: number) => {
                        let heading = false;

                        if (prevName != ol.catName) {
                            heading = true;
                            prevName = ol.catName;
                        }

                        return (
                            <>
                                {heading ? (
                                    <>
                                        <tr className="row_category_name">
                                            <td
                                                colSpan={adminSide ? 8 : 7}
                                                className="heading_td"
                                            >
                                                <b>{ol.catName}</b>
                                            </td>
                                        </tr>

                                        <tr
                                            style={{ backgroundColor: "white" }}
                                            className="row_heading"
                                        >
                                            <th>Product</th>
                                            {supplier==197553?<th>3-Day</th>:<th>7-Day</th>}
                                            <th>Wastage</th>
                                            {!adminSide ? (
                                                <th>Store Stock</th>
                                            ) : (
                                                <>
                                                    <th>Store Stock</th>
                                                    <th>Warehouse</th>
                                                </>
                                            )}
                                            <th>Recommended</th>
                                            <th>Actual</th>
                                            <th>Units</th>
                                        </tr>
                                    </>
                                ) : null}

                                <PurchaseOrderRow
                                    key={i}
                                    index={i}
                                    ol={ol}
                                    handleUpdateOrderLines={
                                        handleUpdateOrderLines
                                    }
                                    isSiteLogin={isSiteLogin}
                                    handleAlert={handleAlert}
                                    adminSide={adminSide}
                                    handleUnsave={handleUnsave}
                                    approvalPo={approvalPo}
                                />
                            </>
                        );
                    })}
            </tbody>
        </table>
    );
}
