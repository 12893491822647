//validates number fields
const numberValidation = (e: any) => {
  const invalidNumberChars = ["-", "+", "e", "E", "."];
  if (invalidNumberChars.includes(e.key)) {
      e.preventDefault();
  }
};

const numberValidationFloat = (e: any) => {
  const invalidNumberChars = ["-", "+", "e", "E"];
  if (invalidNumberChars.includes(e.key)) {
      e.preventDefault();
  }
};
export { numberValidation, numberValidationFloat };
