import GetAppIcon from "@mui/icons-material/GetApp";
import { useEffect, useState } from "react";
import { getAllStores } from "../../utils/Api";
import axios from "axios";
import { Alert, Button, FormControl, FormControlLabel, FormGroup, Switch } from "@mui/material";
import Loader from "../../animations/Loader";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Chart from "react-apexcharts";

export default function Wastage() {
  const url = "https://nitropos-backend.nitrosolutions.co.uk";
  //   const url = "http://10.0.0.174:4022";

  const [siteList, setSiteList] = useState([]);
  const [site, setSite] = useState("");
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [showDashboard, setShowDashboard] = useState(false);
  const [wasteCategory, setWasteCategory] = useState([]);
  const [catWasteSplit, setCatWasteSplit] = useState([]);
  const [catCostSplit, setCatCostSplit] = useState([]);
  const [categoryNames, setCategoryNames] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [expandedProductRows, setExpandedProductRows] = useState([]);
  const [totalWastedProducts, setTotalWastedProducts] = useState(0);
  const [totalCostProducts, setTotalCostProducts] = useState(0);
  const [includeRts, setIncludeRts] = useState(true)
  //alerts
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  //loader
  const [showLoader, setShowLoader] = useState(false);

  //Get wasted totals
  const getWastedTotals = () => {
    let totalCost = 0;
    let totalWaste = 0;
    for (let i = 0; i < catCostSplit.length; i++) {
      totalCost += catCostSplit[i];
    }
    for (let i = 0; i < catWasteSplit.length; i++) {
      totalWaste += catWasteSplit[i];
    }
    setTotalCostProducts(totalCost);
    setTotalWastedProducts(totalWaste);
  };
  useEffect(() => {
    getWastedTotals();
  }, [wasteCategory]);

  //Charts Info
  const chartOptions = {
    chart: {
      width: 600,
      type: "pie",
    },
    labels: categoryNames.slice(0, 15),
    colors: [
      "rgb(255, 99, 132)",
      "rgb(255, 159, 64)",
      "rgb(255, 205, 86)",
      "rgb(75, 192, 192)",
      "rgb(54, 162, 235)",
      "rgb(153, 102, 255)",
      "rgb(255, 0, 0)",
      "rgb(0, 255, 0)",
      "rgb(0, 0, 255)",
      "rgb(255, 255, 0)",
      "rgb(255, 0, 255)",
      "rgb(0, 255, 255)",
      "rgb(128, 0, 0)",
      "rgb(0, 128, 0)",
      "rgb(0, 0, 128)",
    ],
  };

  //Get Sites on page load
  useEffect(() => {
    getAllStores()
      .then((data) => {
        setSiteList(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //Get store/month dependant waste report
  const handleGetWastageReport = () => {
    setShowLoader(true);
    if (
      site === "--Please select a site--" ||
      toDate === "" ||
      fromDate === ""
    ) {
      setShowLoader(false);
      setMessage("Please select site and dates");
      setSeverity("warning");
      setShowAlert(true);
      setWasteCategory([]);
      setTimeout(() => {
        setShowAlert(false);
        setMessage("");
        setSeverity("");
      }, 3000);
    } else {
      let data = {
        siteId: site,
        start: fromDate,
        end: toDate,
        includeRts:includeRts
      };
      axios
        .post(`${url}/claims/report`, data)
        .then((res) => {
          //   console.log(res.data);
          let catNames = [];
          let wasteSplit = [];
          let costSplit = [];
          for (let i = 0; i < res.data.wasteCat.length; i++) {
            let formattedTotalWaste = parseFloat(
              res.data.wasteCat[i].totalWasted
            );
            let formattedTotalCost = parseFloat(
              res.data.wasteCat[i].totalCost.replace("£", "").replace(",", "")
            );

            catNames.push(res.data.wasteCat[i].name);
            wasteSplit.push(formattedTotalWaste);
            costSplit.push(formattedTotalCost);
          }

          setCategoryNames(catNames);
          setCatWasteSplit(wasteSplit);
          setCatCostSplit(costSplit);
          setWasteCategory(res.data.wasteCat);
          setExpandedRows([]);
          setShowLoader(false);
          setShowDashboard(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  //Get category specific products
  const handleCategory = (id) => {
    let data = {
      siteId: site,
      start: fromDate,
      end: toDate,
      categoryId: id,
      includeRts:includeRts
    };
   
    axios
      .post(`${url}/claims/report/category`, data)
      .then((res) => {
        // console.log(res.data);
        setSelectedCategory(res.data.products);
      })
      .catch((err) => console.log(err));
  };

  //Toggle specific category prodcuts
  const toggleRow = (rowId) => {
    if (expandedRows.includes(rowId)) {
      setExpandedRows([]);
    } else {
      setExpandedRows([rowId]);
    }
  };

  //Toggle specific Product breakdown
  const toggleProductRow = (rowId) => {
    if (expandedProductRows.includes(rowId)) {
      setExpandedProductRows([]);
    } else {
      setExpandedProductRows([rowId]);
    }
  };

  return (
    <section className="dashboard_tab">
      <h1>Wastage report</h1>
      <section className="dashboar_selection_row">
        <select onChange={(e) => setSite(e.target.value)}>
          <option>--Please select a site--</option>
          <option value="">All stores</option>
          {siteList.map(function (site) {
            return <option value={site.eposId}>{site.siteName}</option>;
          })}
        </select>
        <div className="date_selection">
          <label>FROM:</label>
          <input type="date" onChange={(e) => setFromDate(e.target.value)} />
        </div>
        <div className="date_selection">
          <label>TO:</label>
          <input type="date" onChange={(e) => setToDate(e.target.value)} />
        </div>
        <FormControl component="fieldset">
                            <FormGroup aria-label="position" row>
                                <FormControlLabel
                                    value="RTS"
                                    control={
                                      <Switch checked={includeRts} onChange={() => setIncludeRts(!includeRts)} inputProps={{ 'aria-label': 'controlled' }} />
                                    }
                                    label="Return To Supplier"
                                    labelPlacement="bottom"
                                />
                            </FormGroup>
                        </FormControl>
        <Button
          variant="contained"
          endIcon={<GetAppIcon />}
          onClick={handleGetWastageReport}
          sx={{
            width: "200px",
            padding: "8px",
            borderRadius: "15px",
          }}
        >
          Submit
        </Button>
      </section>
      {showAlert ? (
        <Alert severity={severity} sx={{ marginTop: "2%" }}>
          {message}
        </Alert>
      ) : showLoader ? (
        <Loader />
      ) : showDashboard ? (
        <>
          {wasteCategory.length >= 1 ? (
            <section className="dashboard_main">
              {wasteCategory.length > 1 ? (
                <div className="table_container">
                  <h2>Totals</h2>
                  <table className="totals_table">
                    <thead>
                      <tr>
                        <td>Overall Wasted</td>
                        <td>Overall Cost</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{totalWastedProducts} items</td>
                        <td>£{totalCostProducts.toFixed(2)}</td>
                      </tr>
                    </tbody>
                  </table>
                  <h2>Categories</h2>
                  <table className="waste_category_table">
                    <thead>
                      <th>Category</th>

                      <th>Total Wasted</th>
                      <th>Total cost</th>
                    </thead>
                    <tbody>
                      {wasteCategory.map((product) => {
                        return (
                          <>
                            <tr>
                              <td>{product.name}</td>

                              <td>{product.totalWasted}</td>
                              <td
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                {product.totalCost}
                                {!expandedRows.includes(product.eposId) ? (
                                  <ExpandMoreIcon
                                    onClick={() => {
                                      handleCategory(product.eposId);
                                      toggleRow(product.eposId);
                                    }}
                                  />
                                ) : (
                                  <ExpandLessIcon
                                    onClick={() => {
                                      handleCategory(product.eposId);
                                      toggleRow(product.eposId);
                                    }}
                                    sx={{
                                      background: "white",
                                      color: "black",
                                      borderRadius: "4px",
                                    }}
                                  />
                                )}
                              </td>
                            </tr>
                            <tr
                              className="category_row"
                              id={`row-${product.eposId}`}
                            >
                              {expandedRows.includes(product.eposId) ? (
                                <td colSpan={3}>
                                  <table
                                    className="product_table"
                                    style={{
                                      width: "100%",
                                    }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>Product</th>
                                        <th>Total Wasted</th>
                                        <th>Total Cost</th>
                                      </tr>
                                    </thead>
                                    {selectedCategory.map((product) => {
                                      return (
                                        <>
                                          <tr>
                                            <td>{product.name}</td>
                                            <td>{product.total}</td>
                                            <td
                                              style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              {product.totalCost}
                                              {!expandedProductRows.includes(
                                                99999 - product.eposId
                                              ) ? (
                                                <ExpandMoreIcon
                                                  onClick={() => {
                                                    toggleProductRow(
                                                      99999 - product.eposId
                                                    );
                                                  }}
                                                />
                                              ) : (
                                                <ExpandLessIcon
                                                  onClick={() => {
                                                    toggleProductRow(
                                                      99999 - product.eposId
                                                    );
                                                  }}
                                                  sx={{
                                                    background: "black",
                                                    color: "white",
                                                    borderRadius: "4px",
                                                  }}
                                                />
                                              )}
                                            </td>
                                          </tr>
                                          <tr>
                                            {expandedProductRows.includes(
                                              99999 - product.eposId
                                            ) ? (
                                              <td colSpan={3}>
                                                <table
                                                  className="damage_table"
                                                  style={{
                                                    width: "100%",
                                                  }}
                                                >
                                                  <thead>
                                                    <tr>
                                                      <th>Damaged</th>
                                                      <th>Out of Date</th>
                                                      <th>
                                                        Out of Temperature
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr>
                                                      <td className="tooltip">
                                                        {site === "" ? (
                                                          <span className="tooltip_text">
                                                            {product
                                                              .breakdown[2]
                                                              .sites.length >=
                                                            1 ? (
                                                              <>
                                                                {product.breakdown[2].sites.map(
                                                                  (where) => {
                                                                    return (
                                                                      <p>
                                                                        {
                                                                          where.siteName
                                                                        }
                                                                        :{" "}
                                                                        {
                                                                          where.qty
                                                                        }
                                                                      </p>
                                                                    );
                                                                  }
                                                                )}
                                                              </>
                                                            ) : (
                                                              <>
                                                                No damaged
                                                                submissions
                                                              </>
                                                            )}
                                                          </span>
                                                        ) : null}
                                                        {product.totalDamaged}
                                                      </td>
                                                      <td className="tooltip">
                                                        {site === "" ? (
                                                          <span className="tooltip_text">
                                                            {product
                                                              .breakdown[0]
                                                              .sites.length >=
                                                            1 ? (
                                                              <>
                                                                {product.breakdown[0].sites.map(
                                                                  (where) => {
                                                                    return (
                                                                      <p>
                                                                        {
                                                                          where.siteName
                                                                        }
                                                                        :{" "}
                                                                        {
                                                                          where.qty
                                                                        }
                                                                      </p>
                                                                    );
                                                                  }
                                                                )}
                                                              </>
                                                            ) : (
                                                              <>
                                                                No out of date
                                                                submissions
                                                              </>
                                                            )}
                                                          </span>
                                                        ) : null}
                                                        {product.totalOutOfDate}
                                                      </td>
                                                      <td className="tooltip">
                                                        {site === "" ? (
                                                          <span className="tooltip_text">
                                                            {product
                                                              .breakdown[1]
                                                              .sites.length >=
                                                            1 ? (
                                                              <>
                                                                {product.breakdown[1].sites.map(
                                                                  (where) => {
                                                                    return (
                                                                      <p>
                                                                        {
                                                                          where.siteName
                                                                        }
                                                                        :{" "}
                                                                        {
                                                                          where.qty
                                                                        }
                                                                      </p>
                                                                    );
                                                                  }
                                                                )}
                                                              </>
                                                            ) : (
                                                              <>
                                                                No out of
                                                                temperature
                                                                submissions
                                                              </>
                                                            )}
                                                          </span>
                                                        ) : null}
                                                        {product.totalOutOfTemp}
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            ) : null}
                                          </tr>
                                        </>
                                      );
                                    })}
                                  </table>
                                </td>
                              ) : null}
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : null}
              <div className="pie_chart_col">
                <div>
                  <h2>Top 15 Amount Breakdown</h2>
                  <Chart
                    options={chartOptions}
                    series={catWasteSplit.slice(0, 15)}
                    type="pie"
                    width="600"
                  />
                </div>
                <div>
                  <h2>Top 15 Cost Breakdown</h2>
                  <Chart
                    options={chartOptions}
                    series={catCostSplit.slice(0, 15)}
                    type="pie"
                    width="600"
                  />
                </div>
              </div>
            </section>
          ) : (
            <h2>There is no information for this store for this period</h2>
          )}
        </>
      ) : null}
    </section>
  );
}
